import { currency } from "utils/shared";
import {
  renderAlingCustomColumn,
  renderIneligibityCustomRow,
  parsedColumnValue,
} from "./helpers";

export const ineligibilityGridColumns = [
  {
    Header: "Loan Purpose",
    width: "11%",
    textWrap: "truncate",
    Cell: ({ row }) => {
      const { lUsage, lUsageFlag } = row?.original || {};
      return renderIneligibityCustomRow(lUsage, lUsageFlag, "flex-start");
    },
  },
  {
    Header: "Prop Usage",
    width: "9%",
    textWrap: "truncate",
    Cell: ({ row }) => {
      const { pUsage, pUsageFlag } = row?.original || {};
      return renderIneligibityCustomRow(pUsage, pUsageFlag, "flex-start");
    },
  },
  {
    Header: "Prop Type",
    width: "24%",
    Cell: ({ row }) => {
      const { pType, pTypeFlag } = row?.original || {};
      return renderIneligibityCustomRow(pType, pTypeFlag, "flex-start");
    },
  },
  {
    Header: () => renderAlingCustomColumn("Min Credit"),
    width: "9%",
    Cell: ({ row }) => {
      const { credit, minCreditFlag } = row?.original || {};
      return renderIneligibityCustomRow(credit, minCreditFlag);
    },
  },
  {
    Header: () => renderAlingCustomColumn("Max LTV (%)"),
    width: "9%",
    Cell: ({ row }) => {
      const { ltv, maxLTVFlag } = row?.original || {};
      return renderIneligibityCustomRow(
        ltv ? parsedColumnValue(ltv) : ltv,
        maxLTVFlag
      );
    },
  },
  {
    Header: () => renderAlingCustomColumn("Max CLTV (%)"),
    width: "9%",
    Cell: ({ row }) => {
      const { cltv, maxCLTVFlag } = row?.original || {};
      return renderIneligibityCustomRow(
        cltv ? parsedColumnValue(cltv) : cltv,
        maxCLTVFlag
      );
    },
  },
  {
    Header: () => renderAlingCustomColumn("Max HCLTV"),
    width: "9%",
    Cell: ({ row }) => {
      const { hcltv, maxHCLTVFlag } = row?.original || {};
      return renderIneligibityCustomRow(
        hcltv ? parsedColumnValue(hcltv) : hcltv,
        maxHCLTVFlag
      );
    },
  },
  {
    Header: () => renderAlingCustomColumn("Max Loan"),
    width: "11%",
    Cell: ({ row }) => {
      const { maxLoan, maxLoanFlag } = row?.original || {};
      return renderIneligibityCustomRow(
        maxLoan ? currency(maxLoan) : maxLoan,
        maxLoanFlag
      );
    },
  },
  {
    Header: () => renderAlingCustomColumn("Max Line"),
    width: "9%",
    Cell: ({ row }) => {
      const { maxLine, maxLineFlag } = row?.original || {};
      return renderIneligibityCustomRow(
        maxLine ? currency(maxLine) : maxLine,
        maxLineFlag
      );
    },
  },
];
