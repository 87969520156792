import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import browserHistory from "utils/browserHistory";
import Login from "view/Login";
import SearchResult from "view/SearchResult";
import LockConfirmWrapper from "view/LockConfirm";
import LockSummary from "view/LockSummary";
import Landing from "view/Landing";
import SearchForm from "view/SearchForm";
import { TooltipTextProvider } from "@elliemae/ds-truncated-tooltip-text";
import { StyledEppsContainerBody } from "view/global/styles/styled";
import Session, { IS_BUYSIDE, IS_LO, IS_JWT_TOKEN } from "services/Session";
import {
  PATH_LOGIN,
  PATH_LOCK_SUMMARY,
  PATH_SEARCH_RESULTS,
  PATH_SEARCH_FOR_PRODUCTS,
} from "routes/constants";

const redirectView = (Component) =>
  !Session.get(IS_LO) && !Session.get(IS_JWT_TOKEN) ? (
    <Redirect to={PATH_LOGIN} />
  ) : (
    <Component />
  );

const Routes = () => {
  const isBuyside = Session.get(IS_BUYSIDE);
  return (
    <StyledEppsContainerBody>
      <Switch>
        <Route
          exact
          name="SearchForProducts"
          path={PATH_SEARCH_FOR_PRODUCTS}
          component={() => redirectView(SearchForm)}
        />
        <Route
          exact
          name="LockSummary"
          path={PATH_LOCK_SUMMARY}
          component={() =>
            redirectView(isBuyside ? LockConfirmWrapper : LockSummary)
          }
        />
        <Route
          exact
          name="SearchResults"
          path={PATH_SEARCH_RESULTS}
          component={() => redirectView(SearchResult)}
        />
        <Redirect to={PATH_SEARCH_RESULTS} />
      </Switch>
    </StyledEppsContainerBody>
  );
};

export const RouteContainer = () => {
  return (
    <TooltipTextProvider>
      <Router history={browserHistory}>
        <Switch>
          <Route exact name="LoginPage" path={PATH_LOGIN} component={Login} />
          <Route name="Landing" path="/" component={Landing} />
        </Switch>
      </Router>
    </TooltipTextProvider>
  );
};

export default Routes;
