import api from "utils/api";
import Common from "common/services/Common";
import Session, {
  ORIGIN_SOURCE,
  APPLICATION_NAME,
  ALL_SUMMARY_PARAMS,
  IS_LO,
  JWT_TOKEN,
  USER_TYPE,
} from "services/Session";

export const rateSelector = async (loanData) => {
  const {
    requestLockStatus,
    requestAction,
    loanInformation,
    relockRequest,
    eppsUserName,
  } = loanData || {};
  const { loanId: loanIdLoan, lienPosition } = loanInformation || {};
  const {
    specificRateDataID: rateDataIdLoan,
    specificProgramID: programIdLoan,
    effectiveDate: effectiveDateLoan,
  } = relockRequest || {};
  const isLOCmode = Session.get(IS_LO);
  const selectedProductData = Session.get(ALL_SUMMARY_PARAMS);

  const { loanId, rateDataId, programId, effectiveDate } =
    selectedProductData || {};
  const applicationName = isLOCmode
    ? Session.get(APPLICATION_NAME) || "ENCW"
    : "EPPS";

  const body = {
    loanId: loanId || loanIdLoan,
    sourceApplicationName: applicationName,
    sourceApplicationFormName: Session.get(ORIGIN_SOURCE) || "lockrequest",
    requestLockStatus,
    requestAction,
    eppsUserName,
    programId: programId || programIdLoan,
    rateDataID: rateDataId || rateDataIdLoan,
    lienPosition: lienPosition || 1,
    effectiveDate: [2, 4, 8].includes(requestAction)
      ? effectiveDate || effectiveDateLoan || ""
      : "",
  };

  let details = {};
  const user = Session.get(USER_TYPE);
  if (user && user.userType === "StandAlone") {
    details = await api.selectProgramRate(
      {
        body: JSON.stringify(body),
        customToken: { value: Session.get(JWT_TOKEN) },
        tokenType: "Bearer",
        loanId,
      },
      {}
    );
  } else {
    details = await api.getWebhookData(
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify(body),
      },
      await Common.getOriginId(),
      "LockRates"
    );
  }
  return details;
};

export const validateRateDocInstance = async (rateDocInstanceID) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getRateDocInstanceV1(
      { patToken, originId },
      rateDocInstanceID
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getRateDocInstanceV2({ jwtToken }, rateDocInstanceID);
  }
  const jsonData = await result.json();
  return result.ok && jsonData.status === 1;
};

export const getRateSheetDocument = async (rateDocInstanceID) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getRateSheetDocumentV1(
      { patToken, originId },
      rateDocInstanceID
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getRateSheetDocumentV2({ jwtToken }, rateDocInstanceID);
  }
  if (result.ok) {
    const blob = await result.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "ratesheet");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
  return result;
};

export const getEncompassSettings = async () => {
  let result = null;
  if (Session.get(IS_LO)) {
    result = await api.getEncompassSettingsV1();
  }
  return result;
};
