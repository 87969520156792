export const FILTERS_CONFIG = {
  // TODO add the default config for each filter pill
  investor: {
    group: "investor",
    label: "Investor",
    fixed: true,
    multi: true,
    mapper: ({ key, value }) => ({ id: value, label: key }),
  },
  lockPeriod: {
    group: "lockPeriod",
    label: "Lock Period",
    fixed: false,
    multi: false,
    mapper: ({ label, value }) => {
      return {
        id: value,
        label: `${label} Days`,
      };
    },
  },
};
