import React from "react";
import { string, bool, func } from "prop-types";
import { useUserData, useLoanDataFieldSelector } from "data/useSelectors";
import { CloseSmall } from "@elliemae/ds-icons";
import { DSToolbar, ToolbarGroup, ToolbarItem } from "@elliemae/ds-toolbar";
import Session, { IS_BUYSIDE } from "services/Session";
import { StyledSlash, StyledDebugInfo } from "../utils/styled";
import { PRICING_TEXT } from "../utils/constants";
import LockDeskStatus from "../LockDeskStatus";
import { APPLY_EPPS_LOCKDESK_SETTINGS } from "../../../../common/constants/AppGlobals";

const HeaderToolbar = ({ isSmartClient, handleOpenModal, loanId }) => {
  const { firstName, lastName } = useUserData();
  const isBuySide = Session.get(IS_BUYSIDE);
  const loanChannel = useLoanDataFieldSelector("loanInformation.loanChannel");
  const isValidChannel = [0, 1, 2, 4].includes(loanChannel);
  const isVisibleLockDesk =
    isValidChannel && !isBuySide && APPLY_EPPS_LOCKDESK_SETTINGS;

  if (isSmartClient) {
    if (isVisibleLockDesk) {
      return (
        <DSToolbar size="compact" withDepth={false} autoFocusOnMount={false}>
          <ToolbarGroup>
            <LockDeskStatus />
          </ToolbarGroup>
        </DSToolbar>
      );
    }
    return null;
  }
  return (
    <DSToolbar size="compact" withDepth={false} autoFocusOnMount={false}>
      <ToolbarGroup>
        {isVisibleLockDesk && (
          <>
            <LockDeskStatus />
            <div style={{ marginLeft: "16px" }} />
            <StyledSlash />
          </>
        )}
        {firstName && lastName && (
          <>
            <StyledDebugInfo data-testid="HeaderToolbar-all-components">
              {firstName} {lastName}
            </StyledDebugInfo>
            <StyledSlash />
          </>
        )}
        {loanId && (
          <>
            <StyledDebugInfo data-testid="HeaderToolbar-all-components">
              {PRICING_TEXT + loanId}
            </StyledDebugInfo>
            <StyledSlash />
          </>
        )}
        <ToolbarItem
          icon={<CloseSmall />}
          onClick={handleOpenModal}
          style={{ marginLeft: "7px", paddingTop: "6px" }}
        />
      </ToolbarGroup>
    </DSToolbar>
  );
};

HeaderToolbar.defaultProps = {
  loanId: "",
  isSmartClient: false,
  handleOpenModal: () => {},
};

HeaderToolbar.propTypes = {
  loanId: string,
  isSmartClient: bool,
  handleOpenModal: func,
};

export default HeaderToolbar;
