import React from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";
import { DSLoadingIndicator } from "@elliemae/ds-loading-indicator";
import Session, { APPLICATION_NAME } from "services/Session";
import GenericLoader from "./components/GenericLoader";

const ScreenLoader = ({ opts }) => {
  const isGenericLoader = Session.get(APPLICATION_NAME) === "TPO";

  return isGenericLoader ? (
    <GenericLoader opts={opts} />
  ) : (
    <DSLoadingIndicator
      message={opts.message && `${opts.message}...`}
      size="m"
      loading={opts.show}
      style={{ color: "white", fontSize: "1.5em" }}
    />
  );
};

ScreenLoader.propTypes = {
  opts: shape({}),
};

ScreenLoader.defaultProps = {
  opts: {},
};

const mapStateToProps = (state) => {
  return {
    opts: state.screenLoader,
  };
};

export default connect(mapStateToProps)(ScreenLoader);
