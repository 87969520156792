/* eslint-disable react/destructuring-assignment */
import React from "react";
import {
  DSTruncatedTooltipText,
  TooltipTextProvider,
} from "@elliemae/ds-truncated-tooltip-text";
import { PopperPositions as Positions } from "@elliemae/ds-popper";
import {
  LockUnlockedRateRequested as LockIcon,
  AlertsDetail,
  FlagSolid,
} from "@elliemae/ds-icons";
import { DSTooltipV2 } from "@elliemae/ds-tooltip";
import CustomHeader from "components/CustomColumnHeader/index";
import { fhaGif, usdaGif, vaGif } from "common/img";
import { DSIconColors } from "@elliemae/ds-icon";
import { Grid } from "@elliemae/ds-grid";
import Session, { IS_LO, JWT_TOKEN, IS_BUYSIDE } from "services/Session";
import Common from "common/services/Common";
import Rights from "common/services/Rights";
import api from "utils/api";
import {
  StyledEligibleRowSelected,
  StyledEligibleProgramLinks,
} from "./styled";
import {
  dispPrice,
  dispApr,
  dispCurrencyMask,
  checkLockProgramStatus,
} from "../../helpers";
import { BUYSIDE_COLS_PROPS } from "./constants";
import DropdownDetails from "./DropdownDetails";

export const splitByEligible = (products) =>
  products.reduce(
    ({ eligible, ineligible }, elem) =>
      elem.deleted === 0
        ? { ineligible, eligible: [...eligible, elem] }
        : { eligible, ineligible: [...ineligible, elem] },
    {
      eligible: [],
      ineligible: [],
    }
  );

const getProgramGuidelines = async (programID) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getProgramGuidelinesV1(
      { patToken, originId },
      programID
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getProgramGuidelinesV2({ jwtToken }, programID);
  }
  return result;
};

const RenderDetails = (
  rowData,
  { programID: programIdAttr },
  onClickPricing
) => {
  const { price, rateStale, rateDate, programID } = rowData;
  const isSelectedRow = programID === programIdAttr;
  const isValidPrice = price !== undefined && price !== null;
  const date = new Date(rateDate).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const time = new Date(rateDate).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  if (isValidPrice) {
    return (
      <StyledEligibleRowSelected isSelected={isSelectedRow}>
        <Grid
          width="100%"
          justifyContent={rateStale === 1 ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {rateStale === 1 && (
            <DSTooltipV2
              title={`Pricing for this product was last updated at ${time} EST on ${date}`}
              placement="top"
              triggerComponent={
                <AlertsDetail size="s" color={DSIconColors.DANGER} />
              }
            />
          )}
          <StyledEligibleProgramLinks onClick={() => onClickPricing(rowData)}>
            details
          </StyledEligibleProgramLinks>
        </Grid>
      </StyledEligibleRowSelected>
    );
  }
  return <CellValue value="--" />;
};

const rightAlign = { width: "100%", textAlign: "right", paddingRight: "3px" };

const CellValue = ({ value }) => {
  return <span style={rightAlign}>{value}</span>;
};

const removeSeconds = (dateTime) => {
  const dateTimeParts = dateTime.split(" ");
  const timeParts = dateTimeParts[1].split(":");
  const newTime = `${timeParts[0]}:${timeParts[1]}`;
  return `${dateTimeParts[0]} ${newTime} ${dateTimeParts[2]}`;
};

export const getEligibleGridColumns = (
  relockRequest = {},
  productOptions,
  programs,
  selectedRow,
  loanData,
  expandedRows,
  windowWidth,
  handleSelectProduct,
  handleRefreshButton,
  handleSelectRow,
  disableLockLinks
) => {
  const isBuySide = Session.get(IS_BUYSIDE);
  const columnWidth = Rights.viewAPR
    ? `${(48 / 6).toFixed(2)}%`
    : `${(48 / 5).toFixed(2)}%`;

  return [
    {
      accessor: "program",
      Header: "Program Name",
      minWidth: "60px",
      width: "52%",
      sortable: true,
      searchable: true,
      Cell: ({ row }) => {
        const {
          id,
          programID,
          rateDetails,
          program,
          nonOptions,
          lockDays,
          locksAvailable,
          rateDate,
          holdback,
          documentation,
          activeGuidelineLink,
          productTypeID,
        } = row.original;
        const isSelectedRow = programID === selectedRow.programID;
        const displayLockIcon = checkLockProgramStatus(
          relockRequest,
          programID,
          rateDetails
        );
        let productIcon;
        let altText;
        if (productTypeID === 2) {
          productIcon = fhaGif;
          altText = "FHA";
        } else if (productTypeID === 3) {
          productIcon = vaGif;
          altText = "VA";
        } else if (productTypeID === 4) {
          productIcon = usdaGif;
          altText = "USDA";
        }
        return (
          <StyledEligibleRowSelected isSelected={isSelectedRow}>
            {displayLockIcon ? (
              <LockIcon
                color={["warning", "600"]}
                title="Rate lock has been requested for this item"
                style={{ marginRight: "8px" }}
              />
            ) : (
              <div style={{ marginRight: "24px" }} />
            )}
            <TooltipTextProvider>
              <DSTruncatedTooltipText
                value={
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: windowWidth / 2.35,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: 5,
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {program} - {documentation}
                        </span>
                        <span>
                          {productIcon && (
                            <img
                              style={{
                                paddingRight: nonOptions !== "" ? "5px" : "0px",
                              }}
                              src={productIcon}
                              alt={altText}
                            />
                          )}
                          {nonOptions !== "" && (
                            <FlagSolid size="s" color={["danger", "900"]} />
                          )}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop: 5,
                        }}
                      >
                        <span>
                          {lockDays}-day rate as of{" "}
                          {removeSeconds(String(rateDate))}
                          <span
                            style={{
                              display: expandedRows[id] ? "inline" : "none",
                            }}
                          >
                            {" "}
                            ({" "}
                            {locksAvailable
                              .split(",")
                              .map((lock) =>
                                lockDays !== Number(lock) ? (
                                  <a
                                    key={lock}
                                    style={{
                                      textDecoration: "none",
                                      pointerEvents: disableLockLinks
                                        ? "none"
                                        : undefined,
                                      color: disableLockLinks
                                        ? "black"
                                        : undefined,
                                    }}
                                    onClick={() =>
                                      handleRefreshButton(lock, loanData)
                                    }
                                  >
                                    {lock}
                                  </a>
                                ) : (
                                  <span
                                    style={{ fontWeight: "bold" }}
                                    key={lock}
                                  >
                                    {lock}
                                  </span>
                                )
                              )
                              .reduce((prev, curr) => [prev, " ", curr])}{" "}
                            )
                          </span>
                          <span>
                            {activeGuidelineLink === 1 &&
                              Rights.viewInvestorGuidelineOverride && (
                                <>
                                  <form
                                    id={`allregs-${programID}`}
                                    method="post"
                                    target="_blank"
                                    style={{ display: "none" }}
                                  >
                                    <input
                                      type="hidden"
                                      name="appId"
                                      id="appId"
                                    />
                                    <input
                                      type="hidden"
                                      name="passkey"
                                      id="passkey"
                                    />
                                  </form>
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      paddingLeft: 5,
                                    }}
                                    onClick={async () => {
                                      const guidelines = await getProgramGuidelines(
                                        programID
                                      );
                                      const {
                                        allRegsUrl,
                                        applicationIdentifier,
                                        passKey,
                                      } = guidelines;
                                      const allregsForm = document.getElementById(
                                        `allregs-${programID}`
                                      );
                                      allregsForm.action = allRegsUrl;
                                      allregsForm.appId.value = applicationIdentifier;
                                      allregsForm.passkey.value = passKey;
                                      allregsForm.submit();
                                    }}
                                  >
                                    Guidelines
                                  </a>
                                </>
                              )}
                          </span>
                        </span>
                        <span>
                          {Rights.viewProfitMargin && (
                            <span
                              style={{ fontWeight: "bold", paddingLeft: 10 }}
                            >
                              Profit Margin: {holdback.toFixed(3)}
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                }
                tooltipPlacement={Positions.TOP}
              />
            </TooltipTextProvider>
          </StyledEligibleRowSelected>
        );
      },
    },
    ...(isBuySide
      ? [
          {
            ...BUYSIDE_COLS_PROPS.profitMargin,
            Header: () => (
              <Grid justifyContent="flex-start" width="100%" pr="xxxs">
                <DSTruncatedTooltipText
                  tooltipPlacement={Positions.TOP}
                  value="Profit Margin"
                />
              </Grid>
            ),
          },
        ]
      : []),
    {
      accessor: "rate",
      Header: () => (
        <Grid justifyContent="flex-end" width="100%" pr="xxxs">
          <DSTruncatedTooltipText
            tooltipPlacement={Positions.TOP}
            value="Rate"
          />
        </Grid>
      ),
      minWidth: "60px",
      width: columnWidth,
      sortable: true,
      Cell: ({ row }) => {
        const { programID, rate, rateDataID } = row.original;
        const isSelectedRow = programID === selectedRow.programID;
        return (
          <StyledEligibleRowSelected isSelected={isSelectedRow}>
            <Grid width="100%" justifyContent="flex-end" alignItems="center">
              <StyledEligibleProgramLinks
                onClick={() => {
                  handleSelectRow(row.original);
                  handleSelectProduct(programID, rateDataID);
                }}
              >
                {rate ? `${rate.toFixed(3)}` : null}
              </StyledEligibleProgramLinks>
            </Grid>
          </StyledEligibleRowSelected>
        );
      },
    },
    {
      accessor: "price",
      Header: () => (
        <Grid justifyContent="flex-end" width="100%" pr="xxxs">
          <DSTruncatedTooltipText
            tooltipPlacement={Positions.TOP}
            value="Price"
          />
        </Grid>
      ),
      minWidth: "60px",
      width: columnWidth,
      sortable: true,
      Cell: ({ row }) => {
        const { price, priceVal, programID } = row.original;
        const isSelectedRow = programID === selectedRow.programID;
        return (
          <StyledEligibleRowSelected isSelected={isSelectedRow}>
            <CellValue value={dispPrice(price, priceVal)} />
          </StyledEligibleRowSelected>
        );
      },
    },
    ...(!isBuySide
      ? [
          {
            accessor: "priceVal",
            Header: () => (
              <Grid justifyContent="flex-end" width="100%" pr="xxxs">
                <DSTruncatedTooltipText
                  tooltipPlacement={Positions.TOP}
                  value="Price ($)"
                />
              </Grid>
            ),
            width: columnWidth,
            minWidth: "60px",
            sortable: true,
            header: {
              customRenderer: () => <CustomHeader label="Price ($)" />,
            },
            Cell: ({ row }) => {
              const { priceVal, programID } = row.original;
              const isSelectedRow = programID === selectedRow.programID;
              return (
                <StyledEligibleRowSelected isSelected={isSelectedRow}>
                  <CellValue value={dispCurrencyMask(priceVal)} />
                </StyledEligibleRowSelected>
              );
            },
          },
          {
            accessor: "pnI",
            Header: () => (
              <Grid justifyContent="flex-end" width="100%" pr="xxxs">
                <DSTruncatedTooltipText
                  tooltipPlacement={Positions.TOP}
                  value="PMT"
                />
              </Grid>
            ),
            width: columnWidth,
            minWidth: "60px",
            sortable: true,
            Cell: ({ row }) => {
              const { programID, pnI } = row.original;
              const isSelectedRow = programID === selectedRow.programID;
              return (
                <StyledEligibleRowSelected isSelected={isSelectedRow}>
                  <CellValue value={dispCurrencyMask(pnI)} />
                </StyledEligibleRowSelected>
              );
            },
          },
        ]
      : []),
    ...(Rights.viewAPR && !isBuySide
      ? [
          {
            accessor: "apr",
            Header: () => (
              <Grid justifyContent="flex-end" width="100%" pr="xxxs">
                <DSTruncatedTooltipText
                  tooltipPlacement={Positions.TOP}
                  value="APR"
                />
              </Grid>
            ),
            minWidth: "60px",
            width: columnWidth,
            sortable: true,
            Cell: ({ row }) => {
              const { apr, programID } = row.original;
              const isSelectedRow = programID === selectedRow.programID;
              return (
                <StyledEligibleRowSelected isSelected={isSelectedRow}>
                  <CellValue value={dispApr(apr)} />
                </StyledEligibleRowSelected>
              );
            },
          },
        ]
      : []),
    {
      accessor: "details",
      Header: () => (
        <Grid justifyContent="flex-end" width="100%" pr="xxxs">
          <DSTruncatedTooltipText
            tooltipPlacement={Positions.TOP}
            value="QM/Det"
          />
        </Grid>
      ),
      minWidth: "60px",
      width: columnWidth,
      sortable: true,
      Cell: ({ row, onClickPricing }) =>
        RenderDetails(row.original, selectedRow, onClickPricing),
    },
  ];
};

const matchInvestor = (program, investors) => {
  const lenderID = program.customLenderID || program.lenderID;
  if (investors.some((item) => item.id === 0)) {
    return program;
  }
  return investors.some(({ id }) => id === lenderID);
};

const matchProduct = (program, products) => {
  const { flagFilter } = program;
  return products.some(({ id }) => id === Math.abs(flagFilter));
};

const addTableRowDetails = (
  filteredPrograms,
  setSlide,
  handleSelectRow,
  relockRequest
) => {
  return filteredPrograms.map((program, index) => ({
    ...program,
    id: index + 1,
    setSlide,
    handleSelectRow,
    relockRequest,
    tableRowDetails: DropdownDetails,
  }));
};

export const filterPrograms = (
  programs,
  activeFilters,
  setSlide,
  handleSelectRow,
  relockRequest
) => {
  const { investor, product, special, term, flagged } = activeFilters || [];
  let filteredPrograms = programs;
  const products = product.concat(special);
  if (investor.length) {
    filteredPrograms = filteredPrograms.filter((program) =>
      matchInvestor(program, investor)
    );
  }
  if (product.length || special.length) {
    filteredPrograms = filteredPrograms.filter((program) =>
      matchProduct(program, products)
    );
  }
  if (term?.label) {
    if (term.label === "All") {
      filteredPrograms = filteredPrograms.filter(
        (program) => program.programTypeID
      );
    } else {
      filteredPrograms = filteredPrograms.filter(
        (program) => program.programTypeID === term.value
      );
    }
  }
  if (flagged) {
    filteredPrograms = filteredPrograms.filter(
      (program) => typeof program.nonOptions === "string"
    );
  } else {
    filteredPrograms = filteredPrograms.filter(
      (program) => program.nonOptions === ""
    );
  }
  filteredPrograms = addTableRowDetails(
    filteredPrograms,
    setSlide,
    handleSelectRow,
    relockRequest
  );
  return filteredPrograms;
};
