import React from "react";

export const marginDetailsColumns = [
  {
    property: "name",
    label: "Name",
    customRenderer: (data) => {
      const value = data.metaData.rowData.name;
      if (data.metaData.index === 0) {
        return <strong>{value}</strong>;
      }
      return value;
    },
  },
  {
    property: "margin",
    label: "Margin",
    customRenderer: (data) => {
      const value = data.metaData.rowData.margin;
      if (data.metaData.index === 0) {
        return <strong>{value}</strong>;
      }
      return value;
    },
  },
];
