import { useMemo } from "react";
import Session, { IS_BUYSIDE } from "services/Session";
import { useLookupOptions } from "components/Form/utils/customHooks";
import generateFilters from "./helpers";

export const useMemoizedFilters = (activeFilters, onChange) => {
  const filtersLookups = useLookupOptions("lockdays");
  return useMemo(() => {
    const filters = [];

    if (Session.get(IS_BUYSIDE) && filtersLookups?.length > 0) {
      filters.push(
        generateFilters(
          "lockPeriod",
          filtersLookups,
          {
            value: activeFilters.lockPeriod,
          },
          onChange
        )
      );
    }
    return filters;
  }, [filtersLookups]);
};
