import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { ComboBoxForm } from "components/Form";
import { searchFormActions } from "store/searchForm";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import { hideAllButtonComboboxStyles } from "../../utils/handlers";

const LoanTermsField = ({ handleSetLoanDataValue }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLoanTermValue = (data) => {
    const { value, pathValue, options: lookupsLoanTerms } = data;

    if (value && value.length > 0) {
      const arrayHasHELOC = lookupsLoanTerms
        .filter((elem) => value.includes(elem.value))
        .some((elem) => elem.heloc);

      const arrayHasNonHELOC = lookupsLoanTerms
        .filter((elem) => value.includes(elem.value))
        .some((elem) => !elem.heloc);

      if (arrayHasHELOC && arrayHasNonHELOC) {
        setIsMenuOpen(false);
        value.pop();
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: "HELOC can not be selected with any other loan term.",
            onCloseCallback: () => setIsMenuOpen(true),
          })
        );
      }
      if (value.length > 8) {
        setIsMenuOpen(false);
        value.pop();
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: "Please specify 8 or fewer loan terms.",
            onCloseCallback: () => setIsMenuOpen(true),
          })
        );
      }
    }

    handleSetLoanDataValue({ value, pathValue });
    dispatch(
      searchFormActions.setIsHELOC({ productType: value, lookupsLoanTerms })
    );
  };

  return (
    <ComboBoxForm
      isMulti
      required
      maxOptions={8}
      label="Loan Terms"
      name="loanTerms"
      pathValue="productType"
      onChangeV2={handleLoanTermValue}
      componentsStyle={hideAllButtonComboboxStyles()}
      onMenuChange={{ onMenuChange: setIsMenuOpen }}
      isMenuOpen={{ isMenuOpen }}
    />
  );
};

LoanTermsField.defaultProps = {
  handleSetLoanDataValue: () => {},
};

LoanTermsField.propTypes = {
  handleSetLoanDataValue: PropTypes.func,
};

export default memo(LoanTermsField);
