import React from "react";
export const PRICING_DROPDOWN_MENU = [
  {
    dsId: "details",
    type: "single",
    label: "Details",
    render: () => <div>Details</div>,
  },
  {
    dsId: "analyzers",
    type: "single",
    label: "Analyzers",
    render: () => <div>Analyzers</div>,
  },
  {
    dsId: "margins",
    type: "single",
    label: "Margins",
    disabled: true,
    render: () => <div>Margins</div>,
  },
];
