import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { DSDropdownMenuV2 } from "@elliemae/ds-dropdownmenu";
import { DSButtonV2, BUTTON_TYPES } from "@elliemae/ds-button";
import { ChevronSmallDown } from "@elliemae/ds-icons";
import { useRateSelectorLien } from "data/useSelectors";
import Rights from "common/services/Rights";
import { PRICING_DROPDOWN_MENU } from "../constants";

const PricingDropdown = ({
  priceAnalyzerModal,
  priceDetailsModal,
  marginDetailsModal,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [options, setOptions] = useState(PRICING_DROPDOWN_MENU);
  const [selectedOptions, setSelectedOptions] = useState({});
  const isArm = useRateSelectorLien("arm", "");
  const programTypeName = useRateSelectorLien("programTypeName", []);
  const isHeloc = programTypeName.includes("HELOC");

  useEffect(() => {
    const optionsCopy = [...options];

    if (!Rights.accessPriceAnalyzer) {
      const analyzerIndex = options.findIndex((el) => el.dsId === "analyzers");
      if (analyzerIndex !== -1) optionsCopy.splice(analyzerIndex, 1);
    }

    if (isArm || isHeloc) {
      const marginIndex = optionsCopy.findIndex((el) => el.dsId === "margins");
      optionsCopy[marginIndex] = {
        ...optionsCopy[marginIndex],
        disabled: false,
      };
    }
    setOptions(optionsCopy);
  }, [isArm, isHeloc]);

  useEffect(() => {
    switch (selectedOptions.dsId) {
      case "analyzers":
        priceAnalyzerModal();
        break;
      case "details":
        priceDetailsModal();
        break;
      case "margins":
        marginDetailsModal();
        break;
      default:
        break;
    }
  }, [selectedOptions]);

  return (
    <Grid p="xxs" width="fit-content" alignItems="center" paddingRight="0px">
      <DSDropdownMenuV2
        isOpened={isOpened}
        options={options}
        selectedOptions={selectedOptions}
        onClickOutside={() => setIsOpened(false)}
        onOptionClick={(_, selectedOption) => {
          if (!selectedOption.disabled) {
            setIsOpened(false);
            setSelectedOptions({
              ...selectedOption,
              [selectedOption.dsId]: true,
            });
          }
        }}
        minWidth={100}
      >
        <DSButtonV2
          buttonType={BUTTON_TYPES.OUTLINE}
          onClick={() => setIsOpened((prevOpened) => !prevOpened)}
        >
          Pricing
          <ChevronSmallDown />
        </DSButtonV2>
      </DSDropdownMenuV2>
    </Grid>
  );
};

PricingDropdown.propTypes = {
  priceAnalyzerModal: func.isRequired,
  priceDetailsModal: func.isRequired,
  marginDetailsModal: func.isRequired,
};

export default PricingDropdown;
