import React, { Fragment, useMemo } from "react";
import { elementType } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { DSSeparatorV2 } from "@elliemae/ds-separator";
import {
  useRateSelectorLien,
  useLoanDataFieldSelector,
} from "data/useSelectors";

import PricingGrid from "components/PricingGrid";
import HedgeLoanCheckbox from "./components/HedgeLoanCheckbox";
import Summary from "../Summary";
import { StyledPricingGridHeader, StyledBuySidePrice } from "./styled";

import { getSummaryData } from "./helpers";

const BuysidePriceSection = ({ pricingDropdownSection }) => {
  const rateDate = useRateSelectorLien("rateDate", null);
  const { effectiveDate = "" } = useLoanDataFieldSelector("relockRequest", {});

  const summaryDetails = useMemo(
    () => getSummaryData({ rateDate, effectiveDate }),
    [rateDate, effectiveDate]
  );

  return (
    <Fragment>
      <StyledPricingGridHeader>
        <div data-testid="BuysidePriceSection-all-components">
          <Summary summaryDetails={summaryDetails} />
        </div>
        <StyledBuySidePrice>
          <Grid
            cols={["repeat(3,auto)"]}
            wrap="nowrap"
            gutter="16px"
            alignItems="center"
          >
            <HedgeLoanCheckbox />
            <Grid height="60%">
              <DSSeparatorV2 isVertical colorType="light" />
            </Grid>
            {pricingDropdownSection}
          </Grid>
        </StyledBuySidePrice>
      </StyledPricingGridHeader>
      <PricingGrid />
    </Fragment>
  );
};

BuysidePriceSection.propTypes = {
  pricingDropdownSection: elementType.isRequired,
};

export default BuysidePriceSection;
