import { v4 as uuidv4 } from "uuid";
import Common from "common/services/Common";
import Rights from "common/services/Rights";
import { isEmpty, partitionArray, safeSum } from "utils/shared";
import { PRICING_DETAILS } from "common/constants/AppGlobals";

const sectionTitle = (description, editable = false, handlerName) => ({
  id: uuidv4(),
  description,
  editable,
  type: "groupTitle",
  handlerName,
});

// TODO: check if p false is a valid scenario
const formatPriceValue = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return "";
  if (p === false) return "0.000";
  return Number(p).toFixed(3);
};

const formatPriceValuePercent = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return "";
  if (p === false) return "0.000%";
  return `${Number(p).toFixed(3)}%`;
};

const parseAdjustment = (adjustment) => {
  return {
    ...adjustment,
    rate: formatPriceValuePercent(adjustment.rate),
    price: formatPriceValue(adjustment.price),
    currentRate: formatPriceValuePercent(adjustment.currentRate),
    currentPrice: formatPriceValue(adjustment.currentPrice),
    change: formatPriceValue(adjustment.change),
    type: "regular",
    id: adjustment.id || uuidv4(),
  };
};

const formatAdjustmentsOrConcessions = (
  adjustments,
  isValidate,
  rowsBeforeEdit,
  isConcession = false,
  pricingHasChanged
) =>
  adjustments.map((adj) => {
    const newAdj = {
      ...adj,
      id: adj.id || uuidv4(),
      type: "regular",
      currentPrice: formatPriceValue(adj.price),
      price: formatPriceValue(adj.price),
    };
    if (
      pricingHasChanged &&
      isValidate &&
      !isEmpty(rowsBeforeEdit) &&
      newAdj.adjustmentType
    ) {
      // this if checks if there is two tables and then we store the old Final price
      const oldPrice = rowsBeforeEdit.find((oldAdj) => oldAdj.id === newAdj.id)
        ?.price;
      newAdj.price = oldPrice || "-";
    }
    if (!pricingHasChanged) {
      newAdj.description = adj.description;
    }
    if (isConcession) {
      newAdj.description = adj.approvalDate;
    }
    return newAdj;
  });

const calculateVariationsTotal = (loanVariations) => {
  return []
    .concat(...Object.values(loanVariations))
    .reduce((sum, { price }) => sum + Number(price), 0);
};

const emptyAdj = {
  price: null,
  approvalDate: "-",
  description: "-",
};

export const generateAdjustments = (
  adjustments = [],
  loanVariations = {},
  isBuyside = false,
  rowsBeforeEdit = [],
  isValidate = false,
  pricingHasChanged
) => {
  const {
    LockExtensionAdjustment: lockExtensions,
    ReLockFeeAdjustment: reLockFees,
    CustomPriceAdjustment: customPriceAdjustments,
    CorporatePriceConcession,
    BranchPriceConcession,
  } = loanVariations;
  let oldLoanVariations;
  let oldExtraPrice;
  if (isValidate && !isEmpty(rowsBeforeEdit)) {
    oldLoanVariations = {
      LockExtensionAdjustment:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === "LockExtensionAdjustment"
        ) || [],
      ReLockFeeAdjustment:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === "ReLockFeeAdjustment"
        ) || [],
      CustomPriceAdjustment:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === "CustomPriceAdjustment"
        ) || [],
      CorporatePriceConcession:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === "CorporatePriceConcession"
        ) || [],
      BranchPriceConcession:
        rowsBeforeEdit.find(
          (adj) => adj.adjustmentType === "BranchPriceConcession"
        ) || [],
    };
    // needed to affect final price of "proposed price" columns
    oldExtraPrice = calculateVariationsTotal(oldLoanVariations);
  }
  const extraPrice = calculateVariationsTotal(loanVariations);
  // Filter Adjustments not allowed for the rows
  let filteredAdjustments = adjustments.map((adj) => parseAdjustment(adj));
  let adjsProfitMarginRows = [];
  const roles = Common.getUserRoles() || [];
  const viewHoldBackRole = 12;
  const viewLOCompRole = 20;
  const hasViewHoldBackRights = roles.some(
    ({ roleId }) => Number(roleId) === viewHoldBackRole
  );
  const hasLOCompViewRight = roles.some(
    ({ roleId }) => Number(roleId) === viewLOCompRole
  );

  [filteredAdjustments, adjsProfitMarginRows] = partitionArray(
    filteredAdjustments,
    ({ adjustmentType }) =>
      adjustmentType !== "Holdback" && adjustmentType !== "Profit"
  );

  if (!hasViewHoldBackRights && !hasLOCompViewRight) {
    filteredAdjustments = filteredAdjustments.filter(
      (adjustment) => adjustment.description !== "L.O. Compensation"
    );
  }

  adjsProfitMarginRows = adjsProfitMarginRows.filter(({ margin }) => !margin);

  // Find the final price
  const getFinalPrice = filteredAdjustments.find(
    (el) => el.adjustmentType === "Final"
  );

  const currentPrice =
    getFinalPrice?.currentPrice &&
    formatPriceValue(Number(getFinalPrice.currentPrice) + extraPrice);

  const price =
    getFinalPrice &&
    formatPriceValue(
      safeSum(
        Number(getFinalPrice.price),
        isValidate && pricingHasChanged ? oldExtraPrice : extraPrice
      )
    );

  const finalPrice = getFinalPrice && {
    ...getFinalPrice,
    description: "FINAL PRICE",
    price,
    ...(getFinalPrice.currentPrice ? { currentPrice } : {}),
  };
  const getBasePrice = filteredAdjustments.find(
    (el) => el.adjustmentType === "Base"
  );
  const basePrice = getBasePrice && {
    ...getBasePrice,
    description: "Base Price",
  };
  const adjustmentRows = filteredAdjustments.filter(
    (el) =>
      el.adjustmentType !== "Base" &&
      el.adjustmentType !== "Final" &&
      !el.margin
  );

  const emptyAdjArr = isBuyside ? [] : [emptyAdj];
  if (PRICING_DETAILS) {
    return [
      ...(basePrice ? [basePrice] : []),
      sectionTitle("Adjustments"),
      ...adjustmentRows,
      ...[
        ...(Rights.viewProfitMargin
          ? [sectionTitle("Profitability"), ...adjsProfitMarginRows]
          : []),
        sectionTitle("Lock Extensions"),
        ...formatAdjustmentsOrConcessions(
          lockExtensions || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          false,
          pricingHasChanged
        ),
        sectionTitle(
          "Re-Lock Fees",
          Rights.editRelockFee,
          "ReLockFeeAdjustment"
        ),
        ...formatAdjustmentsOrConcessions(
          reLockFees || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          false,
          pricingHasChanged
        ),
        sectionTitle(
          "Custom Price Adjustments",
          Rights.editCustomAdjustments,
          "CustomPriceAdjustment"
        ),
        ...formatAdjustmentsOrConcessions(
          customPriceAdjustments || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          false,
          pricingHasChanged
        ),
        sectionTitle(
          "Corporate Price Concessions",
          Rights.editConcessions,
          "CorporatePriceConcession"
        ),
        ...formatAdjustmentsOrConcessions(
          CorporatePriceConcession || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          true,
          pricingHasChanged
        ),
        sectionTitle(
          "Branch Price Concessions",
          Rights.editConcessions,
          "BranchPriceConcession"
        ),
        ...formatAdjustmentsOrConcessions(
          BranchPriceConcession || emptyAdjArr,
          isValidate,
          rowsBeforeEdit,
          true,
          pricingHasChanged
        ),
      ],
      ...(finalPrice ? [finalPrice] : []),
    ];
  }
  return [
    ...(basePrice ? [basePrice] : []),
    sectionTitle("Adjustments"),
    ...adjustmentRows,
    ...[
      ...(Rights.viewProfitMargin
        ? [sectionTitle("Profitability"), ...adjsProfitMarginRows]
        : []),
    ],
    ...(finalPrice ? [finalPrice] : []),
  ];
};
