import React from "react";
import styled from "styled-components";
import { LockLockedNewLockRequested } from "@elliemae/ds-icons";
import { DSTooltipV2 } from "@elliemae/ds-tooltip";
import { DSRadio } from "@elliemae/ds-form";
import { currencyWithDecimals } from "utils/shared";
import { useAppData } from "utils/customHooks/useAppData";
import { NegativeDiscount, AlignRight } from "../styled";

export const StyledPriceCellRenderer = styled.div`
  display: flex;
  width: 100%;
`;

const alignRight = (data) => <AlignRight>{data}</AlignRight>;

const formatNumber = (num) =>
  alignRight(!num || Number.isNaN(num) ? "--" : `${(100 - num).toFixed(3)}`);

const RenderAlignRight = (data) => alignRight(data.value);

const RenderDiscountHeader = (label) => {
  return RenderAlignRight({
    value: (
      <DSTooltipV2
        title={label}
        placement="top"
        triggerComponent={<span>Disc...</span>}
      />
    ),
  });
};

const RenderSelectionCell = ({ metaData }) => {
  const {
    rowData: { id, price, rateDataID, programID, onSelectCallback },
  } = metaData;
  return price ? (
    <DSRadio
      value={id}
      checked={useAppData() === id}
      onChange={(e) => {
        const { value } = e.target;
        onSelectCallback({
          id: value,
          rateDataID,
          programID,
        });
      }}
    />
  ) : null;
};

const RenderPrice = ({ metaData }) => {
  const {
    rowData: { price, isLock },
  } = metaData;
  return (
    <StyledPriceCellRenderer>
      {isLock && (
        <DSTooltipV2
          title="Rate Lock has been requested for this item."
          placement="top"
          triggerComponent={
            <LockLockedNewLockRequested
              size="s"
              color={["brand-primary", "600"]}
              style={{ margin: "0 auto" }}
            />
          }
        />
      )}
      {formatNumber(price)}
    </StyledPriceCellRenderer>
  );
};

const RenderBasePrice = (data) => {
  const {
    rowData: { base },
  } = data.metaData;
  return formatNumber(base);
};

const RenderDiscount = (data) => {
  const {
    rowData: { discount },
  } = data.metaData;
  if (!discount || Number.isNaN(discount)) {
    return alignRight("--");
  }
  const result = Math.round(discount);
  if (result < 0) {
    return alignRight(
      <NegativeDiscount>{currencyWithDecimals(result)}</NegativeDiscount>
    );
  }
  return alignRight(currencyWithDecimals(result));
};

const RenderDiscountPoints = (data) => {
  const {
    rowData: { price },
  } = data.metaData;
  const discountPoints = price && price * -1;
  if (!discountPoints || Number.isNaN(discountPoints)) {
    return alignRight("--");
  }
  const result = discountPoints ? discountPoints.toFixed(3) : "";
  if (result < 0) {
    return alignRight(<NegativeDiscount>{result}</NegativeDiscount>);
  }
  return alignRight(result);
};

export const columns = [
  {
    property: "selected",
    label: "",
    width: 40,
    customRenderer: RenderSelectionCell,
  },
  {
    property: "price",
    label: "Price ",
    header: {
      customRenderer: RenderAlignRight,
    },
    customRenderer: RenderPrice,
    width: 80,
  },
  {
    property: "base",
    label: "Base",
    header: {
      customRenderer: RenderAlignRight,
    },
    customRenderer: RenderBasePrice,
    width: 80,
  },
  {
    property: "discountPoints",
    label: "Disc...",
    header: {
      customRenderer: () => RenderDiscountHeader("Discount/Rebate Points"),
    },
    customRenderer: RenderDiscountPoints,
    width: 80,
  },
  {
    property: "discount",
    label: "Disc...",
    header: {
      customRenderer: () => RenderDiscountHeader("Discount/Rebate"),
    },
    customRenderer: RenderDiscount,
    width: 80,
  },
];

// 'placeholderData' is required as datagrids cannot be empty due to
// alignment issues on smart client
export const getPlaceholderData = (len) => {
  const resultSize = len && len > 0 ? len : 3;
  const result = [];
  for (let i = 0; i < resultSize; i += 1) {
    result.push({
      id: i + 1,
      price: null,
      base: null,
      discount: null,
    });
  }
  return result;
};

export const formatter = "MM/DD/YYYY [at] hh:mm A [ET]";
export const utcFormatter = "YYYY-MM-DDTHH:mm:ss[Z]";
export const EST = "America/New_York";
export const SIZE_SMALL = "80px";
export const SIZE_LARGE = "140px";
export const GRID_HEIGHT = "54px";

export const customOptionsInitialState = [
  { name: "compareRatesGrid2", isCustom: false },
  { name: "compareRatesGrid3", isCustom: false },
];
