import React from "react";
import { isEmpty, cloneDeep, isArray } from "lodash";
import {
  DSFormItemLayout,
  DSComboBox2,
  DSTextBox,
  DSCheckbox,
} from "@elliemae/ds-form";
import { GridContainer } from "@elliemae/ds-grid";
import { DSHeader } from "@elliemae/ds-header";
import { setLoanDataValue } from "store/searchForm/actions";
import { useDispatch } from "react-redux";
import {
  useLoanDataFieldSelector,
  useLookupOptions,
} from "components/Form/utils/customHooks";

const CustomFieldsForm = () => {
  const dispatch = useDispatch();
  const customFields = useLookupOptions("customFields") || [];
  const lockRequestAdditionalFields =
    useLoanDataFieldSelector("lockRequestAdditionalFields").value || [];

  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ["", undefined, "none"].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue));
  };

  const updateLockRequestAdditionalFields = (newFields) => {
    handleSetLoanDataValue({
      value: newFields,
      pathValue: "lockRequestAdditionalFields",
    });
  };

  const getCustomFieldValue = (fieldId) => {
    if (!isEmpty(lockRequestAdditionalFields)) {
      const getCustomValue =
        lockRequestAdditionalFields.find((el) => el.fieldId === fieldId) || {};
      return getCustomValue.value;
    }

    return null;
  };

  const generateOptions = (masterList) => {
    return masterList.map((item) => ({ key: item, value: item }));
  };

  const handleChangeLoanInfo = (value, fieldId) => {
    let newLockRequestAdditionalFields = [];

    if (isArray(lockRequestAdditionalFields)) {
      newLockRequestAdditionalFields = cloneDeep(lockRequestAdditionalFields);
    }

    const field =
      newLockRequestAdditionalFields.find((el) => el.fieldId === fieldId) || {};

    if (!isEmpty(field)) {
      field.value = value;
    } else {
      const customFieldData =
        customFields.find((el) => el.newFieldId === fieldId) || {};
      newLockRequestAdditionalFields.push({
        fieldId: customFieldData.newFieldId,
        dataType: customFieldData.type,
        value,
        description: customFieldData.description,
        options: generateOptions(customFieldData.masterList),
      });
    }

    updateLockRequestAdditionalFields(newLockRequestAdditionalFields);
  };

  const generateOption = (item) => ({
    value: item,
    label: item,
  });

  const generateDropdown = (id, label, optionList, isReadOnly, key) => {
    const options = optionList.map((item) => generateOption(item));
    return (
      <DSFormItemLayout
        key={key}
        containerProps={{ id: { id } }}
        floatingLabel
        labelText={label}
        maxOptions={6}
        inputComponent={DSComboBox2}
        onChange={(e) => {
          handleChangeLoanInfo(e, id);
        }}
        disabled={isReadOnly}
        value={getCustomFieldValue(id)}
        options={options}
      />
    );
  };

  const generateTextBox = (id, label, isReadOnly, type, key) => {
    return (
      <DSFormItemLayout
        key={key}
        containerProps={{ id: { id } }}
        floatingLabel
        labelText={label}
        inputComponent={DSTextBox}
        onChange={(e) => {
          const { value } = e.target;
          handleChangeLoanInfo(value, id);
        }}
        readOnly={isReadOnly}
        value={getCustomFieldValue(id)}
        type={type === "STRING" ? "text" : "number"}
      />
    );
  };

  const generateStateBox = (id, label, isReadOnly, key) => {
    return (
      <DSFormItemLayout
        key={key}
        containerProps={{ id: { id } }}
        floatingLabel
        labelText={label}
        inputComponent={DSTextBox}
        onChange={(e) => {
          const { value } = e.target;
          handleChangeLoanInfo(value.toUpperCase().replace(/[^A-Z]/g, ""), id);
        }}
        readOnly={isReadOnly}
        value={getCustomFieldValue(id)}
        type="text"
        maxLength={2}
      />
    );
  };
  const generateCheckBox = (id, label, isReadOnly, key) => {
    return (
      <div
        style={{
          display: "inline-flex",
        }}
      >
        <DSFormItemLayout
          key={key}
          containerProps={{
            id: { id },
          }}
          inputComponent={DSCheckbox}
          onChange={(e) => {
            handleChangeLoanInfo(e.target.checked ? "False" : "True", id);
          }}
          readOnly={isReadOnly}
          rightAddon={label}
          checked={getCustomFieldValue(id) === "True" ? true : false ?? false}
          type="checkbox"
        />
      </div>
    );
  };
  return (
    <div>
      <DSHeader
        mt="xs"
        mb="xxs"
        text="Custom Fields"
        fontWeight="bold"
        fontSize="16px"
        data-testid="CustomFieldsForm-all-components"
      />

      <GridContainer cols={["20%", "20%", "20%", "20%", "20%"]} gutter="16px">
        {customFields.map((field, index) => {
          if (!field.hidden) {
            switch (field.type) {
              case "DROPDOWNLIST":
              case "DROPDOWN":
              case "YN":
                return generateDropdown(
                  field.newFieldId,
                  field.description,
                  field.masterList,
                  !field.editable,
                  index
                );
              case "DECIMAL":
              case "DECIMAL_1":
              case "DECIMAL_2":
              case "DECIMAL_3":
              case "DECIMAL_4":
              case "DECIMAL_5":
              case "DECIMAL_6":
              case "DECIMAL_7":
              case "DECIMAL_10":
              case "RA_DECIMAL_2":
              case "RA_DECIMAL_3":
              case "INTEGER":
              case "RA_INTEGER":
              case "STRING":
              case "RA_STRING":
                return generateTextBox(
                  field.newFieldId,
                  field.description,
                  !field.editable,
                  field.type,
                  index
                );
              case "STATE":
                return generateStateBox(
                  field.newFieldId,
                  field.description,
                  !field.editable,
                  index
                );
              case "X":
                return generateCheckBox(
                  field.newFieldId,
                  field.description,
                  !field.editable,
                  index
                );
              default:
                return "";
            }
          }
          return null;
        })}
      </GridContainer>
    </div>
  );
};

export default CustomFieldsForm;
