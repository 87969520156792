import React from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { DSButtonV2 } from "@elliemae/ds-button";
import {
  DSInputMask,
  DSCheckbox,
  DSTextBox,
  MASK_TYPES,
} from "@elliemae/ds-form";
import { Delete } from "@elliemae/ds-icons";
import { DSSeparator } from "@elliemae/ds-separator";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { parseDecimals } from "./helpers";
import { StyledCard } from "./styled";

const AdjustmentCard = ({ index, rowCard, onChange, onDeleteRow, errors }) => {
  return (
    <StyledCard>
      <Grid
        cols={["40%", "30%", "25%", "5%"]}
        gutter={3}
        alignItems="flex-start"
      >
        <DSFormLayoutBlockItem
          label="Adjustment Name"
          inputID="nameAdjustment"
          required
          hasError={Boolean(errors?.description)}
          validationMessage={errors?.description?.toString()}
        >
          <DSTextBox
            value={rowCard.description}
            labelText="Adjustment Name"
            onChange={({ target: { value } }) =>
              onChange(value, "description", index)
            }
            maxLength={256}
          />
        </DSFormLayoutBlockItem>
        <DSFormLayoutBlockItem
          label="Price"
          inputID="priceAdjustment"
          required
          hasError={Boolean(errors?.price)}
          validationMessage={errors?.price?.toString()}
        >
          <DSInputMask
            mask={(num) =>
              MASK_TYPES.NUMBER({
                prefix: "",
                integerLimit: 1,
                allowDecimal: true,
                allowNegative: true,
                decimalLimit: 3,
              })(num)
            }
            name="price"
            value={rowCard.price}
            placeholder=""
            labelText="Price"
            required
            onChange={({ target: { value } }) => {
              onChange(value, "price", index);
            }}
            onBlur={({ target: { value } }) => {
              onChange(parseDecimals(value, 3), "price", index);
            }}
          />
        </DSFormLayoutBlockItem>
        <DSCheckbox
          labelText="Persist during price updates"
          checked={rowCard.persist ?? false}
          onChange={() => onChange(!rowCard.persist, "persist", index)}
          name="persistPrice"
          id="persistPrice"
          containerProps={{
            style: { paddingBottom: "0px", paddingTop: "16px" },
          }}
          disabled={rowCard.persistDisabled}
        />
        <div style={{ textAlign: "right" }}>
          <DSButtonV2
            buttonType="icon"
            onClick={() => onDeleteRow(index)}
            size="l"
            aria-label="Delete rowCard"
          >
            <Delete size="m" color={["brand-primary", 600]} />
          </DSButtonV2>
        </div>
      </Grid>
      <DSSeparator dashed style={{ marginBottom: `0`, marginTop: `24px` }} />
    </StyledCard>
  );
};

AdjustmentCard.defaultProps = {
  index: 0,
  rowCard: { price: "", name: "", persist: true, persistDisabled: false },
  errors: { price: "", name: "" },
};

AdjustmentCard.propTypes = {
  onChange: func.isRequired,
  onDeleteRow: func.isRequired,
  index: number,
  rowCard: shape({
    name: string,
    price: oneOfType([number, string]),
    persist: bool,
    persistDisabled: bool,
  }),
  errors: shape({
    name: string,
    price: oneOfType([number, string]),
  }),
};
export default AdjustmentCard;
