import React, { useEffect, memo } from "react";
import { bool, element, node } from "prop-types";
import { DSModalSlide } from "@elliemae/ds-modal-slide";

const ModalSlide = ({ isOpen, header, footer, children }) => {
  const { style } = document.querySelector(".root-container");

  useEffect(() => {
    if (isOpen) {
      style.cssText += "overflow-y: hidden";
    } else {
      style.removeProperty("overflow-y");
    }
    return () => style.removeProperty("overflow-y");
  }, [isOpen]);

  return (
    <DSModalSlide
      fadeIn={300}
      fadeOut={100}
      fullWidth={false}
      getContainer={() => document.getElementById("root")}
      isOpen={isOpen}
      header={header}
      footer={footer}
      data-testid="ModalSlide-all-components"
    >
      {children}
    </DSModalSlide>
  );
};

ModalSlide.defaultProps = {
  isOpen: false,
  footer: null,
  header: null,
};

ModalSlide.propTypes = {
  isOpen: bool,
  header: element,
  footer: element,
  children: node.isRequired,
};

export default memo(ModalSlide);
