import styled, { keyframes } from "styled-components";

export const StyledLoaderMessageWrapper = styled.span`
  color: white;
  font-size: 1.5em;
  margin-top: 5px;
`;

export const StlyedGenericContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerAnimation = keyframes`
100% {
  transform: rotate(360deg);
}`;

export const StyledSpinner = styled.div`
  background-color: transparent;
  &:after {
    content: "";
    background-image: url(${(props) => props.imgUrl});
    width: 52px;
    height: 52px;
    display: flex;
    margin: 0 auto;
    animation: ${SpinnerAnimation} 1s linear infinite;
  }
`;
