import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DSPageHeaderV2 } from "@elliemae/ds-page-header";
import { DSToolbar, ToolbarGroup } from "@elliemae/ds-toolbar";
import { Grid } from "@elliemae/ds-grid";
import { ChevronLeft, ChevronRight, ENCDarkWhite } from "@elliemae/ds-icons";
import Session, { APPLICATION_NAME, IS_LO } from "services/Session";
import {
  StyledDebugInfo,
  StyledSlash,
  StyledColorContainer,
  StyledCarouselContent,
  StyledArrowButtons,
} from "./styled";
import TopRibbon from "./TopRibbon";
import HeaderMenu from "../HeaderMenu";

function SmartClientHeader({ loanId, username, office, loanNumber }) {
  const applicationName = Session.get(IS_LO)
    ? Session.get(APPLICATION_NAME) || "ENCW"
    : "EPPS";

  const renderToolbar = (
    <DSToolbar
      withDepth={false}
      autoFocusOnMount={false}
      size="compact"
      style={{ marginRight: "4px" }}
    >
      <ToolbarGroup>
        <Fragment>
          <StyledDebugInfo>
            {`${username} ${office ? `, ${office}` : ""}`}
          </StyledDebugInfo>
          {username || office ? <StyledSlash /> : ""}
          {loanId ? (
            <>
              <StyledDebugInfo data-testid="SmartClientHeader-all-components">{`Pricing ID: ${String(
                loanId
              )}`}</StyledDebugInfo>
              <StyledSlash />
            </>
          ) : (
            ""
          )}
          {loanNumber ? (
            <>
              <StyledDebugInfo>{`Loan #${loanNumber}`}</StyledDebugInfo>
            </>
          ) : (
            ""
          )}
        </Fragment>
      </ToolbarGroup>
    </DSToolbar>
  );

  const handleClickRight = () => {
    const elementToScroll = document.querySelector("#top-ribbon-body");
    if (elementToScroll) {
      elementToScroll.scrollLeft += 100;
    }
  };

  const handleClickLeft = () => {
    const elementToScroll = document.querySelector("#top-ribbon-body");
    if (elementToScroll) {
      elementToScroll.scrollLeft -= 100;
    }
  };

  return (
    <Fragment>
      <StyledColorContainer>
        <DSPageHeaderV2
          pageTitle={<ENCDarkWhite height={25} style={{ marginLeft: "4px" }} />}
          toolbar={renderToolbar}
          withBottomSeparator={false}
          summary={<div />}
          menu={<div />}
        />
      </StyledColorContainer>
      <HeaderMenu loanId={loanId} applicationName={applicationName} />
      <Grid
        id="smart-client-ribbon"
        wrap="nowrap"
        cols={["24px", "1fr", "24px"]}
        gutter="0px"
      >
        <Grid wrap="nowrap">
          <StyledArrowButtons
            size="s"
            buttonType="text"
            data-testid="carousel-go-left"
            aria-label="Go Left"
            leftIcon={<ChevronLeft />}
            onClick={handleClickLeft}
          />
        </Grid>
        <StyledCarouselContent wrap="nowrap" id="top-ribbon-body">
          <TopRibbon />
        </StyledCarouselContent>
        <Grid wrap="nowrap">
          <StyledArrowButtons
            size="s"
            buttonType="text"
            data-testid="carousel-go-right"
            aria-label="Go Right"
            leftIcon={<ChevronRight />}
            onClick={handleClickRight}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

SmartClientHeader.defaultProps = {
  loanId: 0,
  loanNumber: "",
  username: "",
  office: "",
};

SmartClientHeader.propTypes = {
  loanId: PropTypes.number,
  loanNumber: PropTypes.string,
  username: PropTypes.string,
  office: PropTypes.string,
};

export default SmartClientHeader;
