import React from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import {
  DSInputMask,
  MASK_TYPES,
  DSCheckbox,
  CHECKBOX_VARIANT,
  DSLargeInputText,
  DSTextBox,
} from "@elliemae/ds-form";
import { DSButtonV2 } from "@elliemae/ds-button";
import { Delete } from "@elliemae/ds-icons";
import { CheckBoxWrapper } from "components/WrapContainer/styled";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSeparator } from "@elliemae/ds-separator";
import {
  DSControlledDateTimePicker,
  CONTROLLED_DATE_TIME_PICKER_TYPES,
} from "@elliemae/ds-controlled-form";
import { parseDecimals } from "./helpers";
import { StyledTextTitle, StyledCardTitle, StyledCard } from "./styled";

const ConcessionCard = ({
  rowCard,
  onChange,
  onDeleteRow,
  index,
  title,
  errors,
}) => {
  return (
    <StyledCard>
      <StyledCardTitle>
        <StyledTextTitle>{`${title} ${index + 1}`}</StyledTextTitle>
        <DSButtonV2
          buttonType="icon"
          onClick={() => onDeleteRow(index)}
          size="s"
          aria-label="Delete row"
        >
          <Delete size="s" color={["brand-primary", 600]} />
        </DSButtonV2>
      </StyledCardTitle>
      <Grid
        cols={["33.3%", "33.3%", "33.3%"]}
        py={10}
        gutter={3}
        alignItems="flex-start"
      >
        <DSFormLayoutBlockItem
          label="Price"
          inputID="priceConcession"
          required
          hasError={Boolean(errors?.price)}
          validationMessage={errors?.price?.toString()}
        >
          <DSInputMask
            mask={(num) =>
              MASK_TYPES.NUMBER({
                prefix: "",
                integerLimit: 1,
                allowDecimal: true,
                decimalLimit: 3,
                allowNegative: true,
              })(num)
            }
            name="price"
            value={rowCard.price}
            labelText="Price"
            required
            onChange={({ target: { value } }) => {
              onChange(value, "price", index);
            }}
            onBlur={({ target: { value } }) => {
              onChange(parseDecimals(value, 3), "price", index);
            }}
          />
        </DSFormLayoutBlockItem>
        <DSFormLayoutBlockItem
          label="Approval Date"
          inputID="approvalDateConcession"
          required
          validationMessage={errors?.approvalDate?.toString()}
          hasError={Boolean(errors?.approvalDate)}
        >
          <DSControlledDateTimePicker
            date={rowCard.approvalDate}
            onDateChange={(val) => onChange(val, "approvalDate", index)}
            type={CONTROLLED_DATE_TIME_PICKER_TYPES.DATE.FULL}
            hasError={Boolean(errors?.approvalDate)}
          />
        </DSFormLayoutBlockItem>
        <DSFormLayoutBlockItem
          label="Approved By"
          inputID="approvedByConcession"
          // feedbackMessage="Feedback message"
          // validationMessage="Validation Message too long is being shorted"
        >
          <DSTextBox
            value={rowCard.approvedBy}
            onChange={({ target: { value } }) =>
              onChange(value, "approvedBy", index)
            }
            maxLength={256}
          />
        </DSFormLayoutBlockItem>
      </Grid>
      <Grid cols={["66.6%", "33.3%"]} gutter={3}>
        <DSFormLayoutBlockItem
          label="Approval Reason"
          inputID="approvedReason"
          // feedbackMessage="Feedback message"
          // validationMessage="Validation Message too long is being shorted"
        >
          <DSLargeInputText
            value={rowCard.approvedReason}
            onChange={({ target: { value } }) =>
              onChange(value, "approvedReason", index)
            }
            maxLength={2000}
          />
        </DSFormLayoutBlockItem>
        <CheckBoxWrapper mt="14px">
          <DSCheckbox
            labelText="Persist during price updates"
            checked={rowCard.persist ?? false}
            onChange={() => onChange(!rowCard.persist, "persist", index)}
            variant={CHECKBOX_VARIANT.DEFAULT}
            name="persistPrice"
            id="persistPrice"
            disabled={rowCard.persistDisabled}
          />
        </CheckBoxWrapper>
      </Grid>
      <DSSeparator dashed style={{ marginBottom: `0`, marginTop: `24px` }} />
    </StyledCard>
  );
};

ConcessionCard.defaultProps = {
  errors: { price: "", date: "" },
  index: 0,
  rowCard: {
    title: "",
    approvalDate: "",
    price: "",
    approvedBy: "",
    approvedReason: "",
    persist: true,
    persistDisabled: false,
  },
  title: "",
};

ConcessionCard.propTypes = {
  onChange: func.isRequired,
  onDeleteRow: func.isRequired,
  index: number,
  rowCard: shape({
    name: string,
    price: oneOfType([number, string]),
    persist: bool,
    persistDisabled: bool,
  }),
  title: string,
  errors: shape({
    name: string,
    date: string,
  }),
};
export default ConcessionCard;
