import styled from "styled-components";

export const StyledDSLabel = styled.div`
  div:first-child {
    justify-content: flex-start;
    display: flex;
  }
  .em-ds-labelValue__wrapper {
    margin: 8px 16px 8px 0px;
  }
  .em-ds-label-value__value {
    display: flex;
    align-items: center;
  }
  .em-ds-icon {
    height: 11px;
    margin: 0;
  }
`;
