import React from "react";
import { shape } from "prop-types";
import { DSLoadingIndicator } from "@elliemae/ds-loading-indicator";
import SpinnerSvg from "common/components/themes/vanilla/images/spinner.svg";
import {
  StyledLoaderMessageWrapper,
  StlyedGenericContentWrapper,
  StyledSpinner,
} from "./styled";

const GenericScreenLoader = ({ opts }) => {
  return (
    <DSLoadingIndicator
      loading={opts.show}
      data-testid="GenericScreenLoader-all-components"
      spinner={
        <StlyedGenericContentWrapper>
          <StyledSpinner imgUrl={SpinnerSvg} />
          <StyledLoaderMessageWrapper>
            {opts.message ? `${opts.message}...` : "Loading..."}
          </StyledLoaderMessageWrapper>
        </StlyedGenericContentWrapper>
      }
    />
  );
};

GenericScreenLoader.propTypes = {
  opts: shape({}),
};

GenericScreenLoader.defaultProps = {
  opts: {},
};

export default GenericScreenLoader;
