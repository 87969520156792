import Common from "common/services/Common";
import { closeLoader } from "utils/createAction";
import api from "utils/api";
import moment from "moment";
import Session, {
  ORIGIN_SOURCE,
  APPLICATION_NAME,
  IS_LO,
  JWT_TOKEN,
  USER_TYPE,
} from "services/Session";

const populateOriginDate = (loanData, obj) => {
  const updatedRelockLoanData = {
    effectiveDate: moment
      .tz(obj.requestedDate, "America/New_York")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    specificProgramID: loanData.relockRequest.specificProgramID,
    specificRateDataID: loanData.relockRequest.specificRateDataID,
  };
  return {
    ...loanData,
    requestAction: "4",
    relockRequest: updatedRelockLoanData,
  };
};

const getWebhookRelockData = async (loanData, dispatch, action) => {
  let apiResult = null;
  await Common.getWebhookData(
    loanData,
    await Common.getOriginId(),
    dispatch,
    action,
    await Common.getPATToken()
  )
    .then((result) => {
      if (result && result.code !== undefined) {
        apiResult = null;
      } else {
        apiResult = result;
      }
    })
    .catch(() => {
      dispatch(closeLoader());
    });
  return apiResult;
};

const getRelockData = async (loanData, dispatch, action, lockRequestsEvent) => {
  let fetchRelockData;
  const requests = lockRequestsEvent.map((item) => {
    return getWebhookRelockData(
      populateOriginDate(loanData, item),
      dispatch,
      action
    );
  });
  await Promise.all(requests).then((results) => {
    fetchRelockData = results;
  });
  return fetchRelockData;
};

export const getRatesData = async (
  loanData,
  date,
  isCustom,
  dispatch,
  action
) => {
  const effectiveDate = isCustom
    ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : moment.tz(date, "America/New_York").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const updatedLoanData = {
    ...loanData,
    requestAction: "4",
    relockRequest: {
      ...loanData.relockRequest,
      effectiveDate,
    },
  };
  const ratesData = await getWebhookRelockData(
    updatedLoanData,
    dispatch,
    action
  );
  return ratesData;
};

export const rateSelector = async (
  loanData,
  historicalProgram,
  ignoreEffectiveDate = false
) => {
  const { relockRequest = {} } = loanData;
  const isLOCmode = Session.get(IS_LO);
  const params = {
    loanId: historicalProgram.loanId,
    programId: relockRequest.specificProgramID,
    rateDataId: relockRequest.specificRateDataID,
  };

  const { loanId, rateDataId, programId } = params;
  const applicationName = isLOCmode
    ? Session.get(APPLICATION_NAME) || "ENCW"
    : "EPPS";

  const body = {
    loanId,
    sourceApplicationName: applicationName,
    sourceApplicationFormName: Session.get(ORIGIN_SOURCE) || "secondarylock",
    requestLockStatus: loanData.requestLockStatus,
    requestAction: 4,
    eppsUserName: "",
    programId,
    rateDataID: rateDataId,
    lienPosition:
      loanData && loanData.loanInformation
        ? loanData.loanInformation.lienPosition
        : 1,
    effectiveDate: !ignoreEffectiveDate
      ? loanData.relockRequest.effectiveDate || ""
      : null,
  };
  let details = {};
  const user = Session.get(USER_TYPE);
  if (user && user.userType === "StandAlone") {
    details = await api.selectProgramRate(
      {
        body: JSON.stringify(body),
        customToken: { value: Session.get(JWT_TOKEN) },
        tokenType: "Bearer",
        loanId,
      },
      {}
    );
  } else {
    details = await api.getWebhookData(
      {
        headers: { "X-Elli-PAT": await Common.getPATToken() },
        body: JSON.stringify(body),
      },
      await Common.getOriginId(),
      "LockRates"
    );
  }
  return details;
};

export default getRelockData;
