import styled from "styled-components";

export const StyledPricingGridHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const StyledBuySidePrice = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
