import { FILTERS_CONFIG } from "./constants";

const generateFilters = (name, options = [], config, onFilterChange) => {
  const { mapper, ...defaultConfig } = FILTERS_CONFIG[name];
  return {
    ...defaultConfig,
    options: options.map(mapper),
    onChange: (value) => {
      if (onFilterChange) onFilterChange(name, value);
    },
    ...config,
  };
};

export default generateFilters;
