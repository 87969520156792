import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { useLoanDataFieldSelector } from "data/useSelectors";
import { PATH_SEARCH_RESULTS } from "routes/constants";
import { HISTORICAL_PRICING_TEXT } from "../HeaderMenu/utils/constants";

function PricingNotificationHeader() {
  const [isOpen, setOpen] = useState(false);
  const { effectiveDate = "" } = useLoanDataFieldSelector("relockRequest", {});
  const { tabId } = useSelector(({ landing }) => landing);

  useEffect(() => {
    const momentEffectiveDate = moment.tz(
      effectiveDate,
      "YYYY-MM-DDTHH:mm:ss a z",
      "America/New_York"
    );
    setOpen(
      tabId === PATH_SEARCH_RESULTS &&
        !!effectiveDate &&
        momentEffectiveDate.isBefore(moment())
    );
  }, [effectiveDate, tabId]);

  const getEffectiveDate = () =>
    moment.tz(effectiveDate, "America/New_York").format("MM-DD-yyyy hh:mm A");

  return (
    <DSBanner
      body={`Effective Date ${getEffectiveDate()} EST`}
      isOpen={isOpen}
      label={HISTORICAL_PRICING_TEXT}
      onClose={() => setOpen(false)}
      type={BANNER_TYPES.WARNING}
      data-testid="PricingNotificationHeader-all-components"
    />
  );
}

export default PricingNotificationHeader;
