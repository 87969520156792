import moment from "moment";

export const getSummaryData = ({ rateDate, effectiveDate }) => {
  return [
    rateDate
      ? {
          title: "Rate Sheet Date",
          value: moment
            .tz(rateDate, "America/New_York")
            .format("MM/DD/YYYY [at] hh:mm A [ET]"),
        }
      : {},
    effectiveDate
      ? {
          title: "Effective Date",
          value: moment
            .tz(effectiveDate, "America/New_York")
            .format("MM/DD/YYYY [at] hh:mm A [ET]"),
        }
      : {},
  ];
};
