import React, { useState, useEffect } from "react";
import { shape, arrayOf, number, func, bool } from "prop-types";
import { DSToolbar } from "@elliemae/ds-toolbar";
import { DSFilterBar } from "@elliemae/ds-filterbar";
import FilterPills from "view/SearchResult/components/FilterPills";
import { useLookup } from "data/useSelectors";
import { DSControlledCheckbox } from "@elliemae/ds-controlled-form";
import {
  useLookupOptions,
  useLoanDataFieldSelector,
} from "components/Form/utils/customHooks";
import { DSPillGroupV2, DSPillV2 } from "@elliemae/ds-pills";
import { FlagSolid } from "@elliemae/ds-icons";
import { get } from "lodash";
import Rights from "common/services/Rights";
import { useMemoizedFilters } from "./hooks";
import { StyledDSToolbarWrapper } from "./styled";
function EligibleProductsToolbar({
  onFilterChange,
  activeFilters,
  refreshPrograms,
  showTarget,
  showFlagged,
}) {
  const investorsOptions = useLookupOptions("investors");
  const standardProducts = useLookupOptions("standardProducts");
  const requestedProducts =
    useLoanDataFieldSelector("standardProducts", []) || [];
  const selectedProducts = standardProducts.filter((obj) =>
    requestedProducts.value.includes(obj.value)
  );
  const specialProducts = useLookupOptions("specialProducts");
  const requestedSpecialProducts =
    useLoanDataFieldSelector("specialProducts", []) || [];
  const selectedSpecialProducts = specialProducts.filter((obj) =>
    requestedSpecialProducts.value.includes(obj.value)
  );
  const clientsettings = useLookup("clientSettings", "");
  const flaggedSetting = get(clientsettings, "[0].options.flagged");
  const [checked, setChecked] = useState(
    get(clientsettings, "[0].options.flagged")
  );
  const [openInvestorPill, setOpenInvestorPill] = useState(false);
  const [openProductPill, setOpenProductPill] = useState(false);
  const [showProductPill, setShowProductPill] = useState(true);
  const [openSpecialProductPill, setOpenSpecialProductPill] = useState(false);
  const [showSpecialProductPill, setShowSpecialProductPill] = useState(true);
  const [selectedInvestorOptions, setSelectedInvestorOptions] = useState({
    0: { id: 0, label: "All" },
  });
  const calcInitial = () => {
    const initialProducts = selectedProducts.reduce((acc, obj) => {
      acc[obj.value] = { id: obj.value, label: obj.label };
      return acc;
    }, {});
    if (Object.keys(initialProducts).length > 0) {
      setShowProductPill(true);
    } else {
      setShowProductPill(false);
    }
    return initialProducts;
  };
  const calcSpecialInitial = () => {
    const initialSpecialProducts = selectedSpecialProducts.reduce(
      (acc, obj) => {
        acc[obj.value] = { id: obj.value, label: obj.label };
        return acc;
      },
      {}
    );
    if (Object.keys(initialSpecialProducts).length > 0) {
      setShowSpecialProductPill(true);
    } else {
      setShowSpecialProductPill(false);
    }
    return initialSpecialProducts;
  };
  const [selectedProductsOptions, setSelectedProductsOptions] = useState(
    calcInitial
  );
  const [
    selectedSpecialProductsOptions,
    setSelectedSpecialProductsOptions,
  ] = useState(calcSpecialInitial);
  const productOptions = useLoanDataFieldSelector("productOptions", []) || [];
  const filters = useMemoizedFilters(activeFilters, onFilterChange);

  const handleFlaggedChange = (value) => {
    setChecked(value);
    onFilterChange("flagged", value);
  };

  const mappedInvestorsOptions = React.useMemo(() => {
    return [
      { dsId: "0", label: "All", type: "checkbox" },
      ...(investorsOptions || [])
        .map(({ label, value }) => ({
          dsId: String(value),
          label,
          type: "checkbox",
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [investorsOptions]);

  const mappedProductsOptions = React.useMemo(() => {
    return (selectedProducts || [])
      .map(({ label, value }) => ({
        dsId: String(value),
        label,
        type: "checkbox",
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [refreshPrograms]);

  const mappedSpecialProductsOptions = React.useMemo(() => {
    return (selectedSpecialProducts || [])
      .map(({ label, value }) => ({
        dsId: String(value),
        label,
        type: "checkbox",
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [refreshPrograms]);

  const handleInvestorOptionClick = (_, optionObject) => {
    const { dsId, label } = optionObject;
    let optionsObject = { ...selectedInvestorOptions };
    if (dsId === "0" && !optionsObject[dsId]) {
      optionsObject = {};
    }
    if (dsId !== "0" && optionsObject["0"]) {
      delete optionsObject["0"];
    }
    if (optionsObject[dsId]) delete optionsObject[dsId];
    else optionsObject[dsId] = { id: Number(dsId), label };
    setSelectedInvestorOptions(optionsObject);
    onFilterChange("investor", Object.values(optionsObject));
  };

  const selectedInvestorOptionsValues = Object.values(selectedInvestorOptions);

  const handleProductOptionClick = (_, optionObject) => {
    const { dsId, label } = optionObject;
    const optionsObject = { ...selectedProductsOptions };

    if (optionsObject[dsId]) delete optionsObject[dsId];
    else optionsObject[dsId] = { id: Number(dsId), label };
    setSelectedProductsOptions(optionsObject);
    onFilterChange("product", Object.values(optionsObject));
  };

  const selectedProductOptionsValues = Object.values(selectedProductsOptions);

  const handleSpecialProductOptionClick = (_, optionObject) => {
    const { dsId, label } = optionObject;
    const optionsObject = { ...selectedSpecialProductsOptions };

    if (optionsObject[dsId]) delete optionsObject[dsId];
    else optionsObject[dsId] = { id: Number(dsId), label };
    setSelectedSpecialProductsOptions(optionsObject);
    onFilterChange("special", Object.values(optionsObject));
  };

  const selectedSpecialProductOptionsValues = Object.values(
    selectedSpecialProductsOptions
  );

  const getTooltipValue = (arr) => {
    return arr
      .slice(3)
      .map((val) => val.label)
      .join(", ");
  };

  const handleInvestorPillRemove = (id) => {
    const optionsObject = { ...selectedInvestorOptions };
    delete optionsObject[id];

    setSelectedInvestorOptions(optionsObject);
    onFilterChange("investor", Object.values(optionsObject));
  };

  const handleProductPillRemove = (id) => {
    const optionsObject = { ...selectedProductsOptions };
    delete optionsObject[id];

    setSelectedProductsOptions(optionsObject);
    onFilterChange("product", Object.values(optionsObject));
  };

  const handleSpecialProductPillRemove = (id) => {
    const optionsObject = { ...selectedSpecialProductsOptions };
    delete optionsObject[id];

    setSelectedSpecialProductsOptions(optionsObject);
    onFilterChange("special", Object.values(optionsObject));
  };

  useEffect(() => {
    setSelectedProductsOptions(calcInitial);
    setSelectedSpecialProductsOptions(calcSpecialInitial);
    setSelectedInvestorOptions({ 0: { id: 0, label: "All" } });
    onFilterChange("product", Object.values(selectedProductsOptions));
    onFilterChange("special", Object.values(selectedSpecialProductsOptions));

    setChecked(flaggedSetting);
    handleFlaggedChange(flaggedSetting);
  }, [refreshPrograms]);

  return (
    <StyledDSToolbarWrapper>
      <DSToolbar withDepth={false}>
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            {showTarget && <FilterPills />}
            {Rights.viewTrueInvestorName && (
              <div style={{ marginRight: "2px" }}>
                <DSFilterBar
                  minWidth={350}
                  filters={filters}
                  renderToolbar={() => null}
                />
                <DSPillGroupV2>
                  <DSPillV2
                    label="Investor"
                    type="dropdown"
                    onDropdownClick={() => setOpenInvestorPill(true)}
                    dropdownProps={{
                      options: Object.values(mappedInvestorsOptions),
                      selectedOptions: selectedInvestorOptions,
                      isOpened: openInvestorPill,
                      onClickOutside: () => setOpenInvestorPill(false),
                      onOptionClick: handleInvestorOptionClick,
                    }}
                  />
                  {selectedInvestorOptionsValues.map(({ id, label }, index) => {
                    if (index < 3)
                      return (
                        <DSPillV2
                          key={id}
                          label={label}
                          type="removable"
                          onRemove={() => handleInvestorPillRemove(id)}
                        />
                      );
                    if (index === 3)
                      return (
                        <DSPillV2
                          tooltipValue={getTooltipValue(
                            selectedInvestorOptionsValues
                          )}
                          label={`+ ${
                            selectedInvestorOptionsValues.length - index
                          }`}
                          type="value"
                        />
                      );

                    return null;
                  })}
                </DSPillGroupV2>
              </div>
            )}
            {showProductPill && (
              <div style={{ marginRight: "2px" }}>
                <DSPillGroupV2>
                  <DSPillV2
                    label="Standard Products"
                    type="dropdown"
                    onDropdownClick={() => setOpenProductPill(true)}
                    dropdownProps={{
                      options: Object.values(mappedProductsOptions),
                      selectedOptions: selectedProductsOptions,
                      isOpened: openProductPill,
                      onClickOutside: () => setOpenProductPill(false),
                      onOptionClick: handleProductOptionClick,
                    }}
                  />
                  {selectedProductOptionsValues.map(({ id, label }, index) => {
                    if (index < 3)
                      return (
                        <DSPillV2
                          key={id}
                          label={label}
                          type="removable"
                          onRemove={() => handleProductPillRemove(id)}
                        />
                      );
                    if (index === 3)
                      return (
                        <DSPillV2
                          tooltipValue={getTooltipValue(
                            selectedProductOptionsValues
                          )}
                          label={`+ ${
                            selectedProductOptionsValues.length - index
                          }`}
                          type="value"
                        />
                      );
                    return null;
                  })}
                </DSPillGroupV2>
              </div>
            )}
            {showSpecialProductPill && (
              <div style={{ marginRight: "2px" }}>
                <DSPillGroupV2>
                  <DSPillV2
                    label="Special Products"
                    type="dropdown"
                    onDropdownClick={() => setOpenSpecialProductPill(true)}
                    dropdownProps={{
                      options: Object.values(mappedSpecialProductsOptions),
                      selectedOptions: selectedSpecialProductsOptions,
                      isOpened: openSpecialProductPill,
                      onClickOutside: () => setOpenSpecialProductPill(false),
                      onOptionClick: handleSpecialProductOptionClick,
                    }}
                  />
                  {selectedSpecialProductOptionsValues.map(
                    ({ id, label }, index) => {
                      if (index < 3)
                        return (
                          <DSPillV2
                            key={id}
                            label={label}
                            type="removable"
                            onRemove={() => handleSpecialProductPillRemove(id)}
                          />
                        );
                      if (index === 3)
                        return (
                          <DSPillV2
                            tooltipValue={getTooltipValue(
                              selectedSpecialProductOptionsValues
                            )}
                            label={`+ ${
                              selectedSpecialProductOptionsValues.length - index
                            }`}
                            type="value"
                          />
                        );
                      return null;
                    }
                  )}
                </DSPillGroupV2>
              </div>
            )}
            {productOptions.value.length > 0 && showFlagged && (
              <>
                <div
                  style={{
                    display: "inline-flex",
                    marginTop: "4px",
                    marginLeft: "3px",
                  }}
                >
                  <DSControlledCheckbox
                    data-testid="custom-data-testid"
                    checked={checked}
                    name="flagged"
                    label=""
                    id="flaggedCheckbox"
                    onChange={(value) => handleFlaggedChange(value)}
                  />

                  <FlagSolid
                    size="s"
                    color={["danger", "900"]}
                    style={{ marginLeft: "3px" }}
                  />
                  <p style={{ marginTop: "1px", marginLeft: "3px" }}>Flagged</p>
                </div>
              </>
            )}
          </div>
        </div>
      </DSToolbar>
    </StyledDSToolbarWrapper>
  );
}

EligibleProductsToolbar.defaultProps = {
  activeFilters: {},
  refreshPrograms: false,
  showTarget: false,
  showFlagged: false,
  onFilterChange: () => null,
};

EligibleProductsToolbar.propTypes = {
  activeFilters: shape({ investor: arrayOf(number) }),
  refreshPrograms: bool,
  showTarget: bool,
  showFlagged: bool,
  onFilterChange: func,
};

export default EligibleProductsToolbar;
