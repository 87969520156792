import React, { useEffect, useState, useCallback } from "react";
import { arrayOf, bool, func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "@elliemae/ds-data-table";
import { ModalHeader } from "@elliemae/ds-modal-slide";
import ModalSlide from "components/ModalSlide";
import { useAppData } from "utils/customHooks/useAppData";
import { DSTabs, DSTab, TabTypes } from "@elliemae/ds-tabs";
import {
  useLoanDataFieldSelector,
  useLoanInformationField,
  useSummaryDetailsField,
  useLookup,
} from "data/useSelectors";
import { getAdjustmentDetails } from "view/AdjustmentDetails/AdjustmentDetailsAction";
import GridPriceDetails from "view/SearchResult/components/GridPriceDetails/GridPriceDetails";
import { transformRateSelectorDataToSlide } from "view/SearchResult/components/GridPriceDetails/helpers";
import { selectProduct } from "data/lockSummary/actions";
import { StyledDataTableWrapper } from "view/global/styles/styled";
import { setRedirectTabAction } from "data/landing/actions";
import { PATH_LOCK_SUMMARY } from "routes/constants";
import Session, { ALL_SUMMARY_PARAMS } from "services/Session";
import EligibleProductsToolbar from "./EligibleProductsToolbar";
import RowsGridEmpty from "../../../../components/RowsGridEmpty/RowsGridEmpty";
import { getEligibleGridColumns, filterPrograms } from "./helpers";
import ProgramDetailsModal from "../../../LockSummary/components/ProgramDetailsModal/ProgramDetailsModal";
import useEligibleProductsGrid from "./useEligibleProductsGrid";
import { setAllRatesRowData } from "../../../../Qualification/QualificationActions";

function EligibleProductsGrid({
  rows,
  activeFilters,
  onFilterChange,
  isLoanPrograms,
  refreshPrograms,
  onSearch,
  updateAdjustmentDetailsCache,
}) {
  const dispatch = useDispatch();
  const setEligibleProducts = useAppData("setEligibleProducts");
  const clientsettings = useLookup("clientSettings", "");
  const { relockRequest } = useSelector(({ EPPS }) => EPPS.loanInfo || {});
  const lienPosition = useLoanInformationField("lienPosition", 1);
  const productOptions = useLoanDataFieldSelector("productOptions", []) || [];
  const loanTerms = useLoanDataFieldSelector("productType", []) || [];
  const requestAction = useLoanDataFieldSelector("requestAction");
  const requestLockStatus = useLoanDataFieldSelector("requestLockStatus");
  const disableLockLinks = requestAction === 2 && requestLockStatus === 2;
  const lookupsLoanTerms = useLookup("loanTerms");
  let loanTermsTabs;
  if (Array.isArray(loanTerms) && lookupsLoanTerms) {
    loanTermsTabs = lookupsLoanTerms.filter((obj) =>
      loanTerms.includes(obj.value)
    );
    loanTermsTabs.unshift({ label: "All" });
  }

  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const {
    lien: { armIndexValue = 0, arm = false } = {},
    loan: { mortgageType = "" } = {},
  } = useSummaryDetailsField();

  const [expandedRows, setExpandedRows] = useState({ 1: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSelectProduct = (programID, rateDataID) => {
    Session.set(ALL_SUMMARY_PARAMS, {
      loanId,
      programId: programID,
      rateDataId: rateDataID,
    });
    dispatch(setRedirectTabAction(PATH_LOCK_SUMMARY));
  };

  const handleRefreshButton = useCallback((lock, loanData) => {
    const lockData = { lockDays: [Number(lock)] };
    const refreshLoanData = { ...loanData, ...lockData };
    onSearch(refreshLoanData);
  });

  const {
    columns,
    handleSelectRow,
    slide,
    setSlide,
    programSlide,
    setProgramSlide,
    filteredRows,
  } = useEligibleProductsGrid({
    dispatch,
    loanId,
    relockRequest,
    lienPosition,
    productOptions,
    armIndexValue,
    mortgageType,
    getEligibleGridColumns,
    transformRateSelectorDataToSlide,
    getAdjustmentDetails,
    activeFilters,
    filterPrograms,
    selectProduct,
    clientsettings,
    rows,
    refreshPrograms,
    arm,
    expandedRows,
    windowWidth,
    handleSelectProduct,
    handleRefreshButton,
    updateAdjustmentDetailsCache,
    disableLockLinks,
  });

  useEffect(() => {
    setEligibleProducts(filteredRows.length);
  }, [filteredRows]);

  useEffect(() => {
    setExpandedRows({ 1: true });
  }, [refreshPrograms]);

  // Handle window resize for program row width calculation
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (clientsettings !== "") {
    return (
      <>
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DSTabs type={TabTypes.SUBTABS}>
            {loanTermsTabs.map((tab, idx) => (
              <DSTab
                key={tab.label.concat(idx)}
                tabId={`tab${idx + 1}`}
                title={tab.label}
                onClick={() => {
                  onFilterChange("term", tab);
                }}
              />
            ))}
          </DSTabs>
        </div>
        <div
          style={{
            height: "calc(100% - 60px)",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <EligibleProductsToolbar
            activeFilters={activeFilters}
            onFilterChange={onFilterChange}
            refreshPrograms={refreshPrograms}
            showTarget
            showFlagged
          />
          <ModalSlide
            header={
              <ModalHeader
                title="Pricing Details"
                onClose={() =>
                  setSlide({
                    open: false,
                    data: null,
                    rowData: null,
                    rateData: null,
                  })
                }
              />
            }
            isOpen={slide.open}
          >
            {slide.data && (
              <div style={{ padding: 16 }}>
                <GridPriceDetails isSlide data={slide.data} />
              </div>
            )}
          </ModalSlide>
          <ProgramDetailsModal
            isSlide
            isOpen={programSlide}
            toggleModalSlide={() => setProgramSlide(false)}
          />
          <StyledDataTableWrapper>
            <DataTable
              paginated
              sortable
              searchFilters
              isExpandable
              uniqueRowAccessor="id"
              expandedRows={expandedRows}
              onRowExpand={(newExpandedRows) => {
                setExpandedRows(newExpandedRows);
              }}
              colsLayoutStyle="auto"
              columns={columns}
              data={filteredRows}
              hiddenColumns={!productOptions.length ? ["options"] : []}
              rowKey="programID"
              cellRendererProps={{
                onClickProgramName: (rowData) => {
                  dispatch(selectProduct(rowData));
                  setProgramSlide(true);
                },
                onClickPricing: (rowData) => {
                  setSlide({
                    rowData,
                    open: false,
                    data: null,
                    rateData: null,
                  });
                },
                onClickAllRates: (rowData) => {
                  dispatch(selectProduct(rowData));
                  dispatch(setAllRatesRowData(rowData));
                },
              }}
              onRowClick={(rowData) => {
                handleSelectRow(rowData);
              }}
              noResultsPlaceholder={
                <RowsGridEmpty loanPrograms={isLoanPrograms} rows={rows} />
              }
            />
          </StyledDataTableWrapper>
        </div>
      </>
    );
  }
  return null;
}

EligibleProductsGrid.defaultProps = {
  rows: [],
  activeFilters: [],
  onFilterChange: () => null,
  isLoanPrograms: false,
  refreshPrograms: false,
  onSearch: () => null,
  updateAdjustmentDetailsCache: () => null,
};

EligibleProductsGrid.propTypes = {
  rows: arrayOf(),
  activeFilters: arrayOf(),
  isLoanPrograms: arrayOf(),
  onFilterChange: func,
  refreshPrograms: bool,
  onSearch: func,
  updateAdjustmentDetailsCache: func,
};

export default EligibleProductsGrid;
