import React from "react";
import { string, bool } from "prop-types";
import Session, { ORIGIN_SOURCE, IS_BUYSIDE } from "services/Session";
import { StyledSlash, StyledTitle, StyledNameView } from "../utils/styled";
import { TITLE_TEXT } from "../utils/constants";

const HeaderTitle = ({ title, isSmartClient }) => {
  const isSecondary = Session.get(ORIGIN_SOURCE) === "secondarylock";
  const isBuySide = Session.get(IS_BUYSIDE);

  return (
    <StyledTitle marginLeft={isSecondary}>
      {!isSmartClient && (
        <>
          {TITLE_TEXT}{" "}
          <StyledSlash data-testid="HeaderTitle-all-components" margin />{" "}
        </>
      )}
      {!isSecondary ? (
        <StyledNameView marginLeft={isSmartClient}>{title}</StyledNameView>
      ) : (
        <StyledNameView marginLeft={isSmartClient}>
          {isBuySide ? "Buy Side" : "Sell Side"}: {title}
        </StyledNameView>
      )}
    </StyledTitle>
  );
};

HeaderTitle.defaultProps = {
  title: "",
  isSmartClient: false,
};

HeaderTitle.propTypes = {
  title: string,
  isSmartClient: bool,
};

export default HeaderTitle;
