/* eslint-disable no-console */
/* eslint-disable  no-alert */
import mainApi from "utils/api";
import queryParams from "utils/queryParams";
import {
  TRANS_ID_KEY,
  JWT_TOKEN_KEY,
  USER_TYPE_KEY,
  BORROWER_ID_KEY,
  WEBHOOKTOKEN_KEY,
  HOSTAPPLICATION_KEY,
  PAT_TOKEN_KEY,
  ORIGIN_ID_KEY,
  CRED_KEY,
  SUMMARY_PARAMS,
} from "common/constants/AppGlobals";
import jwtDecode from "jwt-decode";
import ssf from "@elliemae/em-ssf-guest";
import LoginService from "view/Login/LoginService";
import moment from "moment";
import Session, {
  ORIGIN_ID,
  PAT_TOKEN,
  APPLICATION_NAME,
  ORIGIN_SOURCE,
  ORIGIN_TRANSACTION_TYPE,
  ORIGIN_LOCKID,
  IS_LO,
  JWT_TOKEN,
  USER_TYPE,
} from "services/Session";
import { setUserRights } from "./Rights";

const ACTION_UPDATE_CREDENTIALS = "updateCredentials";
class Common {
  constructor() {
    this.params = queryParams(window.location.search);
    this.TransKey = TRANS_ID_KEY;
    this.OriginKey = ORIGIN_ID_KEY;
    this.JWT_Key = JWT_TOKEN_KEY;
    this.UserType_Key = USER_TYPE_KEY;
    this.Borrower_Id_Key = BORROWER_ID_KEY;
    this.WebhookToken_Key = WEBHOOKTOKEN_KEY;
    this.HostApplication_Key = HOSTAPPLICATION_KEY;
    this.Summary_params = SUMMARY_PARAMS;
    this.PAT_token_key = PAT_TOKEN_KEY;
    this.CRED_key = CRED_KEY;
    this.isInvalidToken = "INVALID_ACCESS_TOKEN";
    this.isInvalidCredentials = "INVALID_CREDENTIAL";
    this.isBadRequest = "BAD_REQUEST";
    this.Application_name = "SOURCE_APPLICATION_NAME";
    this.Origin_source = "ORIGIN_SOURCE";
    this.Origin_transaction_type = "ORIGIN_TRANSACTION_TYPE";
    this.Origin_lockID = "ORIGIN_LOCKID";
    this.Origin_isUpdate = "ORIGIN_LOCK_UPDATE";
    this.applicationType = "APPLICATION_TYPE";
  }

  async getOriginId() {
    const isPATexpired = this.isPATexpired();
    if (isPATexpired) await this.refreshGetOrigin();
    return sessionStorage.getItem(this.OriginKey);
  }

  getToken() {
    const user = Session.get(USER_TYPE);
    if (user && user.userType === "StandAlone") return Session.get(JWT_TOKEN);
    return this.getPATToken();
  }

  async validatePATExpired() {
    if (Session.get(IS_LO)) {
      const isPATExpired = this.isPATexpired();
      if (isPATExpired) {
        await this.refreshGetOrigin();
      }
    }
  }

  async getPATToken() {
    const isPATexpired = this.isPATexpired();
    if (isPATexpired) await this.refreshGetOrigin();
    return sessionStorage.getItem(this.PAT_token_key);
  }

  isPATexpired() {
    const token = sessionStorage.getItem(this.PAT_token_key);
    const actualDate = moment();
    const expiredDate = moment(jwtDecode(token).exp * 1000);
    return expiredDate.diff(actualDate, "seconds") <= 30;
  }

  async refreshGetOrigin(isEPCtransaction) {
    try {
      if (isEPCtransaction) {
        const transactionObj = await ssf.getObject("transaction");
        const data = await transactionObj.refreshOrigin();
        Session.set(PAT_TOKEN, data.partnerAccessToken);
        Session.set(ORIGIN_ID, data.id);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async sourceApplicationName() {
    try {
      const transactionObj = await ssf.getObject("application");
      const data = await transactionObj.getDescriptor();
      Session.set(APPLICATION_NAME, data.name);
    } catch (err) {
      console.log(err);
    }
  }

  async getOrigin() {
    const originTransactionType = Session.get(ORIGIN_TRANSACTION_TYPE);
    const headers = { "X-Elli-PAT": await this.getPATToken() };
    const data = await mainApi.getOrigin(
      { headers },
      await this.getOriginId(),
      Session.get(ORIGIN_SOURCE),
      originTransactionType,
      Session.get(ORIGIN_LOCKID)
    );
    return data;
  }

  async getUserData() {
    const user = Session.get(USER_TYPE);
    if (user && user.userType === "StandAlone") {
      const jwtToken = await Session.get(JWT_TOKEN);
      const result = await mainApi.getUserDataV2({ jwtToken });
      return result;
    }
    const result = await mainApi.getUserDataV1();
    return result;
  }

  async getLockDeskData(loanChannel) {
    if (Session.get(IS_LO)) {
      const result = await mainApi.getLockDeskHours(
        await this.getOriginId(),
        loanChannel
      );
      return result;
    }
    return null;
  }

  async getLockDeskValidateData(validateData) {
    if (Session.get(IS_LO)) {
      const result = await mainApi.getLockDeskValidateStatus(validateData);
      return result;
    }
    return null;
  }

  async getOnrpAmount(loanChannel, loanAmount) {
    if (Session.get(IS_LO)) {
      const result = await mainApi.setOnrpAccruedAmount(
        loanChannel,
        loanAmount
      );
      return result;
    }
    return null;
  }

  async setClientRoles() {
    try {
      const data = Session.get(IS_LO)
        ? await mainApi.getClientRole()
        : await mainApi.getClientRoleV2();
      setUserRights(data?.rights || []);
      return data?.rights;
    } catch (e) {
      setUserRights([]);
      return null;
    }
  }

  async setUserRoles() {
    try {
      let data = [];
      if (Session.get(IS_LO)) {
        data = await mainApi.getUserRolesV1();
      } else {
        const jwtToken = await Session.get(JWT_TOKEN);
        data = await mainApi.getUserRoles({
          tokenType: "Bearer",
          customToken: { value: jwtToken },
        });
      }
      sessionStorage.setItem("userRoles", window.btoa(JSON.stringify(data)));
    } catch (e) {
      sessionStorage.setItem("userRoles", window.btoa(JSON.stringify([])));
    }
  }

  getUserRoles() {
    const storedRoles = sessionStorage.getItem("userRoles");
    const roles = storedRoles ? JSON.parse(window.atob(storedRoles)) : [];
    return roles;
  }

  async reconcileInvalidCredentials() {
    const applicationObject = await ssf.getObject("application");
    const updatedCredentials = await applicationObject.performAction(
      ACTION_UPDATE_CREDENTIALS
    );
    if (
      !updatedCredentials.error &&
      Object.keys(updatedCredentials).length > 0
    ) {
      return this.validateCredentials(updatedCredentials);
    }
    if (updatedCredentials.error) {
      alert(
        "The server has encountered an internal error. Please contact the administrator."
      );
      return false;
    }
    return false;
  }

  async validateCredentials(credentials) {
    const { username, password } = credentials;
    try {
      const authenticateUser = await LoginService.validateCredentials(
        username,
        password
      );

      const isInvalidToken = () => {
        return authenticateUser.code === this.isInvalidToken;
      };

      const isInvalidCredential = () => {
        return authenticateUser.code === this.isInvalidCredentials;
      };

      const isInvalidUser = () => {
        if (authenticateUser.code) {
          if (authenticateUser.code === this.isBadRequest) {
            const { summary } = authenticateUser;
            if (JSON.parse(summary).summary === "EPPS User Not Found.") {
              return true;
            }
            throw new Error();
          }
        }
        return false;
      };

      if (authenticateUser && authenticateUser.token) {
        return authenticateUser;
      }

      if (isInvalidToken() || isInvalidCredential() || isInvalidUser()) {
        alert("Invalid credentials");
        return this.reconcileInvalidCredentials();
      }
      return false;
    } catch (e) {
      alert("Something went wrong getting credentials.");
      return false;
    }
  }

  createRequest(request, orderIndex) {
    const sandboxInteraction = ssf.getObject("sandbox-interaction");
    sandboxInteraction.then((data) => {
      data.createTransaction(request, true, orderIndex);
    });
  }

  async createTransaction(transactionRequest) {
    let result;
    try {
      const transactionObject = await ssf.getObject("transaction");
      const transactionData = await transactionObject.create(
        transactionRequest
      );
      result = transactionData.id;
    } catch (err) {
      console.log(err);
    }
    return result;
  }

  async closeApplication() {
    const user = Session.get(USER_TYPE);
    if (user && user.userType === "StandAlone") {
      window.close();
    } else if (Session.get(IS_LO)) {
      try {
        const transactionObject = await ssf.getObject("transaction");
        transactionObject.close();
      } catch (err) {
        console.log(`Error ${err}`);
      }
    }
  }

  async getWebhookData(loanData, OriginId, webhookDispatch, action, PAT) {
    const data = await mainApi.getWebhookData(
      {
        headers: { "X-Elli-PAT": PAT },
        body: JSON.stringify({
          ...loanData,
          sourceApplicationName: Session.get(APPLICATION_NAME) || "ENCW",
          sourceApplicationFormName:
            Session.get(ORIGIN_SOURCE) || "lockrequest",
        }),
      },
      OriginId,
      "Rates"
    );
    // if mapping found, then webhookDispatch
    if (webhookDispatch && action && data && !data.code)
      webhookDispatch(action(data));
    return data;
  }

  async getEpcTransaction(transactionId) {
    const data = await mainApi.getEpcTransaction({}, transactionId);
    // if mapping found, then dispatch
    return data;
  }

  async getUpdatedfield(originalLoanData) {
    const data = await mainApi.getUpdatedfield(
      {
        headers: { "X-Elli-PAT": await this.getPATToken() },
        body: JSON.stringify({ ...originalLoanData }),
      },
      await this.getOriginId()
    );
    return data;
  }

  async getEpcWebhookData(
    loanData,
    transactionId,
    webhookDispatch,
    action,
    PAT
  ) {
    const data = await mainApi.getEpcWebhookData(
      {
        headers: { "X-Elli-PAT": PAT },
        body: JSON.stringify(loanData),
      },
      transactionId,
      "SubmitRates"
    );
    // if mapping found, then webhookDispatch
    if (data && !data.code) webhookDispatch(action(data));
    return data;
  }

  async completeTransaction(transactionId) {
    const body = { transactionStatus: "Completed" };
    mainApi.completeTransaction({ body: JSON.stringify(body) }, transactionId);
  }

  createMarkup(text) {
    return { __html: text };
  }
}

export default new Common();
