import { customChangedValueColumn } from "./helpers";

export const columns = [
  {
    Header: "Field Name",
    accessor: "label",
  },
  {
    Header: "Active Lock Value",
    accessor: "current",
  },
  customChangedValueColumn,
];
